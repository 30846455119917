* {
    font-family: var(--font-family-default);
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: var(--font-family-default);
    vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.authBaseScreen {
    display: flex;
    width: 100%;
    height: 100vh;
    transition: all .3s ease-in-out;
    background-color: #fff;
    overflow: hidden;
    position: relative;

    .new-logo{
        position: absolute;
        top:28px;
        left:24px;
        height:24px;
        cursor:pointer;
    }

    // right side
    .rightSide {
        /* stylelint-disable-next-line */
        width: 51.756954612005856%;
        position: relative;
        background: #fff;
        box-sizing: border-box;
        display: flex!important;
        align-items:center;
        justify-content:center;

        .titleText {
            margin: 30px 40px 0;

            h3 {
                display: flex;
                align-items: center;

                img {
                    height: 34px;
                    width: auto;
                }

            }

            .web-title {
                font-weight: 700;
                font-size: 40px;
                line-height: 43px;
                color: var(--color-default-text);
                margin: 20px 0 0;
            }

            .page-title {
                font-size: 24px;
                color: var(--color-default-text);
                margin-top: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                opacity: 0.5;
            }
        }
    }

    // left side
    .leftSide-wrapper{
        background: linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%);
        /* stylelint-disable-next-line */
        width: 48.243045387994144%;
        height: 100%;
    }
    
    .leftSide {
        background: url('../../assets/images/new_login_bg.png') no-repeat center/ 100% 100%;
        background-size: contain;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
}
/* stylelint-disable-next-line */
@media (max-width:720px){
    .authBaseScreen{
        .leftSide, .leftSide-wrapper{
            display:none;
        }
    
        .rightSide{
            width:100%;
        }
    }
}
/* stylelint-disable-next-line */
@media (max-width:576px){
    .authBaseScreen{
        .rightSide{
            display:block;
        }
    }
}