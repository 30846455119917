@import "../../utils";

.shortcutList {
    max-height: 77vh;
    position: fixed;
    top: 80px;
    right: 10px;
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    width: 274px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    border-radius: 8px;
    transition: .2s all ease-in-out;
    display: grid;
    grid-template-rows: 57px 1px 1fr;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 57px;

        &--title {
            margin-left: 20px;
            color: var(--color-default-text, #3C3E49);
            font-family: var(--font-family-default);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
        }

        &--closeBtn {
            padding: 0;
            margin: 0 20px 0 0;
            background: none;
            border: none;
            font-size: 21.5px;
            cursor: pointer;

            em {
                position: relative;
                top: 2px;
            }
        }
    }

    &__seperator {
        width: 100%;
        margin-bottom: 16px;
        background-color: #DFE0E6;
        height: 1px;
    }

    &__list {
        @include scrollbar;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        overflow: auto;
    }

    &__content {
        width: 100%;
        row-gap: 200px;
        padding: 12px 20px 16px;
        font-family: var(--font-family-default);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--color-default-text);
        overflow: auto;
        box-sizing: border-box;

        ul {
            list-style-type: disc;
            padding-left: 12px;
        }

        li{
            font-family: var(--font-family-default);
            color: var(--color-default-text, #3C3E49);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
    }

    &.active {
        transition: all .2s ease-in-out;
        visibility: visible;
        opacity: 1;
    }
}