.container-search {
    position: relative;
    align-self: center;
    margin-right: 40px;
    z-index: 100;

    .control-result {
        display: flex;
        align-items: center;
        height: 48px;
        padding: 0 10px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;

        .text-result {
            margin-right: 10px;
            font-size: 12px;
            color: #BFBFBF;
        }

        .text-search{
            font-size: 12px;
            color: #000;
        }

        .divider {
            width: 1px;
            height: calc(100% - 20px);
            background-color: #BFBFBF;
            margin-right: 10px;

            // margin-top: 10px;
            // margin-bottom: 10px;
        }



        .icon-up {
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-down {
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-close {
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .expandable-container {
        width: 304px;
        height: 48px;
        background: #fff;
        box-shadow: 0 5px 9px rgb(212 212 212 / 50%);
        border-radius: 4px;
        position: relative;
        top: 0;
        right: 0;
        transition: width 0.3s ease;

        &.minimized {
            width: 48px;
            box-sizing: content-box;
            height: 48px;
            background: var(--interactive-bg-t-1, #F5F5F5);
            box-shadow: 0 5px 9px rgb(212 212 212 / 50%);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &.expanded {
            border: 1px solid var(--blue-grey-10, #3C3E49);
        }

        .icon-container {
            position: absolute;
            top: 0;
            right: 0;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: #fff;
                font-size: 24px;
            }
        }

        .input-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .icon-expanded {
            margin: 12px 8px;
        }

        input[type='text'] {
            margin-bottom: 5px;
            margin-top: 6px;
            width: 90%;
            color: #000;
            height: calc(100% - 10px);
            border: none;
            font-size: 14px;

            &:focus {
                outline: none;
                border-color: transparent;
            }

            &::placeholder { 
                color: var(--blue-grey-8, var(--base-blue-grey-8, #83889E));
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
              }
        }

        .button-container {
            display: flex;
            justify-content: space-between;

            button {
                font-size: 14px;
            }
        }
    }
}