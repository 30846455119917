.leftDrawer {
    box-sizing: border-box;
    width: 389px;
    padding: 14px 17px 8px 14px;
    background: #F5F5F5;
    border: 0.5px solid #BFBFBF;
    box-shadow: 0 5px 9px rgb(212 212 212 / 50%);
    border-radius: 5px;
    position: fixed;
    bottom: 30px;
    left: 30px;
    height: 86vh;
    transform: translateX(-419px);
    visibility: hidden;
    transition: 200ms all ease-in-out;
    will-change: transform;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 99;

    &__nav {
        display: flex;
        flex-direction: row;
        margin-top: 18px;

        &--btn {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            flex: 1;
            padding: 7px 0 !important;
            text-align: center;
            transition: 200ms all ease-in-out;
            color: var(--color-default-text);

            em {
                font-size: 21px;
                margin-right: 9px;
            }

            &.active {
                background-color: #B388FF;
                border-radius: 3px;
                color: #fff;
            }

            &:disabled {
                cursor: not-allowed;
            }
        }
    }

    &__content {
        flex-grow: 1;
        flex-basis: 0;

        &--error {
            display: grid;
            place-items: center;
            height: 100%;

            .errorCard {
                display: grid;
                grid-template-columns: 50px 1fr;
                gap: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }

                .error__message {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                }
            }
        }
    }

    &.active {
        transform: translateX(0);
        visibility: visible;
    }

    button {
        cursor: pointer;
        border: none;
        background: transparent;
        padding: 0;
        margin: 0;
        color: var(--color-default-text);
    }

    .leftDrawer__closeBtn {
        em {
            font-size: 24px;
            position: relative;
            top: 2px;
        }
    }
}

.tab_loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}