.editInput {
    /* layout */
    display: flex;
    padding: 0 3px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    resize: none;
    border: none;
    box-sizing: border-box;
    width: 100%;

    /* style */
    border-radius: 2px;
    background: var(--base-blue-grey-2, #ECEDF0);

    /* typography */
    color: var(--basic-black, #000);
    font-feature-settings: 'clig'off, 'liga'off;
    font-family: var(--font-family-default);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    /* 166.667% */
    line-height: 20px;
    margin:4px 0;

    &:focus {
        outline: none;
    }

    &__wrapper {
        width: 100%;
    }

    &__buttons {
        display: flex;
        padding-top: 2px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    &__btn {
        all: unset;
        cursor: pointer;

        /* typography */

        /* Body/XS/Regular */
        font-family: var(--font-family-default);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        /* 133.333% */
        line-height: 16px;
        letter-spacing: 0.12px;


        &.btnSave {
            color: var(--base-purple-5, #651FFF);
        }

        &.btnCancel {
            color: var(--base-blue-grey-10, #3C3E49);
        }

        &:disabled {
            opacity: .5;
        }
    }
}
