@keyframes loadingIconAnim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.selectBox {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    /*     width: 180px;
    max-width: 180px; */

    summary {
        display: flex;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        width: fit-content;
        user-select: none;
        cursor: pointer;
        color: var(--interactive-label-purple, #6200EA);
        border-radius: 5px;
        border-top: 2px solid var(--interactive-bg-p-2, #fff);
        border-right: 5px solid var(--interactive-bg-p-2, #fff);
        border-bottom: 2px solid var(--interactive-bg-p-2, #fff);
        border-left: 5px solid var(--interactive-bg-p-2, #fff);

        /* Body/SM/Medium */
        font-family: var(--font-family-default);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;

        /* 142.857% */
        letter-spacing: 0.14px;
        white-space:nowrap;

        svg {
            display: flex;
            padding: 0 4.333px;
            justify-content: center;
            align-items: center;

            path {
                stroke: var(--interactive-label-purple, #6200EA);
                stroke-width: 5;
            }
        }

        &:hover,
        &.active {
            border-radius: 5px;
            border-top: 2px solid var(--interactive-bg-p-2, #F3EBFF);
            border-right: 5px solid var(--interactive-bg-p-2, #F3EBFF);
            border-bottom: 2px solid var(--interactive-bg-p-2, #F3EBFF);
            border-left: 5px solid var(--interactive-bg-p-2, #F3EBFF);
            background: var(--interactive-bg-p-2, #F3EBFF);

            &--loadingIcon {
                display: inline-block;
                width: 40px;
                height: 40px;

                &::after {
                    content: " ";
                    display: block;
                    width: 24px;
                    height: 24px;
                    margin: 8px;
                    border-radius: 50%;
                    border: 6px solid #aaa;
                    border-color: #aaa transparent;
                    animation: loadingIconAnim 1.2s linear infinite;
                }
            }
        }

        &.disabled {
            cursor: not-allowed !important;
            user-select: none;
            color: rgb(107 106 106) !important;

            svg {
                path {
                    stroke: rgb(107 106 106);
                }
            }

            &:hover {
                border-color: #fff;
                background: #fff;
            }
        }

        &.float-right {
            margin-left: auto;
        }

        &.float-left {
            margin-right: auto;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 145px;
        z-index: 1000;
        border-radius: 8px;
        border: 1px solid var(--base-blue-grey-2, #ECEDF0);
        box-sizing: border-box;

        /* Main Shadows/E100 */
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        pointer-events: none;

        &.active {
            pointer-events: auto;
        }

        li {
            display: flex;
            padding: 12px 16px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            cursor: pointer;
            background: var(--0, #FFF);
            white-space: nowrap;
            color: var(--base-blue-grey-10, #3C3E49);

            /* Body/XS/Regular */
            font-family: var(--font-family-default);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            /* 133.333% */
            line-height: 16px;
            letter-spacing: 0.12px;

            &:first-child {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            &:last-child {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            p {
                flex: 1 0 0;
            }

            &.disabled {
                pointer-events: none;
                user-select: none;
                color: rgb(107 106 106) !important;
                cursor: not-allowed;
            }

            &:hover {
                background: var(--base-purple-1, #F3EBFF);
            }

            &.border-top {
                border-top: 1px solid var(--blue-grey-3, #dfe0e6);
            }
        }

    }
}
