.framesTab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 39px;

        &--left {
            display: flex;
            align-items: center;
        }

        &--layoutBtn {
            font-size: 24px;
            color: #292D32;
            border-radius: 3px;
            width: 34px;
            height: 34px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.2s ease-in-out;

            &.active {
                background-color: #B388FF;
                color: #fff;
            }

            &.layoutBtn_first {
                margin: 0 6px 0 12px;
            }
           
            em {
                position: relative;
                top: 2px;
            }
        }
    }

    &__list {
        margin-top: 21px;
        height: 68vh;
        overflow: hidden auto;
        padding-left: 4px;
        padding-right: 4px;
        flex-basis: 0;
        flex-grow: 1;

        &--list {
            &.layout-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 14px;
            }

            &.layout-column {
                .frameItem {
                    height: auto;
                    display: flex;
                    padding: 10px 15px;
                    border-radius: 4px;

                    &:hover {
                        background-color: #fff;
                    }

                    &__img {
                        display: none;
                    }

                    &__title {
                        position: static;
                        color: #292D32;
                        background-color: transparent;
                        backdrop-filter: none;
                        padding: 0;
                    }
                }
            }
        }

    }
}

.frameItem {
    height: 170px;
    position: relative;
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    user-select: none;

    &__logo {
        background: var(--purple-gradients-2, linear-gradient(135deg, #fff 0%, #fff 100%));
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 40px);

        img {
            object-fit: cover;
            object-position: center;
            border-radius: 4px;
        }
    }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
    }

    &__title {
        position: absolute;
        bottom: 0;

        // background-color: rgba($color: #fff, $alpha: 0.5);
        background: rgb(179 136 255 / 17%);
        backdrop-filter: blur(25px);
        width: 100%;
        padding: 10px 15px;
        color: var(--color-default-text);
        box-sizing: border-box;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}