.wb_toast {
    border-radius: 8px !important;
    background: #FFF !important;
    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%) !important;
    height: auto !important;
    padding: 8px 16px !important;
    min-height: unset; 
    display: flex;
    align-items: center;

    .Toastify__toast-icon {
        width: 21px !important;
        height: 21px !important;
        margin-right: 20px;
    }

    .Toastify__close-button {
        align-self: center;
        opacity: 1;
        display: flex;
        align-items: center;
        margin-left: 15px;

        svg {
            width: 20px;
            height: 20px;

            path {
                fill: #3C3E49;
            }
        }
    }

    &.Toastify__toast--success {
        border: 1px solid #00C853;
    }

    &.Toastify__toast--error {
        border: 1px solid #D50000;
    }
    
    &.Toastify__toast--warning {
        border: 1px solid var(--Base-Amber-5, #FFAB00);
    }

    .Toastify__toast-body {
        padding: 0;
    }

    .Toastify__toast-body > div:last-child {
        font-family: var(--font-family-default);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-align: center;
    }

    &__feedback {
        border: 1px solid var(--Brand-Purple-4, #651FFF);
    }
    
    &.interactive {
        border: 1px solid var(--Brand-Purple-4, #651FFF);
        padding: 8px 8px 8px 16px !important;
        align-items: start;
        cursor: default;
        user-select: none;
        
        .Toastify__toast-body {
            flex-grow: 0;
        }

        .interactive_toast {
            font-size: 14px;
            line-height: 17px;
            width: 100%;
            box-sizing: border-box;
            color: var(--color-default-text);
            display: flex;
            align-items: center;
            white-space: nowrap;
            
            &__txt {
                min-width: 202px;
            }

            &__btn {
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-inline: 16px;
                border-radius: 8px;
                background: var(--Interactive-Bg-P3, #7C4DFF);
                color: #fff;
                margin-left: 21px;
                cursor: pointer;
            } 
        }
    }
}

#feedback-custom-toast {
    width: 415px;
    padding: 8px 8px 8px 16px !important;
    align-items: start;
    cursor: default;

    .Toastify__toast-body {
        flex-grow: 0;
    }
}

.Toastify__toast-container {
    width: auto;
    max-width: 60vw;
}

.Toastify__toast-container--bottom-center {
    bottom: 42px; /* from canvas re-design */
}

.Toastify__toast {
    margin-bottom: 24px; /* from canvas re-design */
}