.checkbox-input {
    &__input {
        position: absolute;
        top: auto;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;
        white-space: nowrap;
        outline: none;

        + label {
            display: block;
            position: relative;
            padding: 0.5em;
            padding-left: 2.2em;
            max-width: calc(100% - 2em);
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 9px;
            user-select: none;
            color: var(--color-default-text);
        }

        + label::before,
        + label::after {
            content: "";
            position: absolute;
            transition: all 0.25s ease;
        }

        + label::before {
            content: '';
            width: 16px;
            height: 16px;
            background-color: #FFF;
            border: 1px solid #C0C3CE;
            border-radius: 4px;
            top: 0.15em;
            left: 0;
            box-sizing:border-box;
        }

        + label::after {
            color: #FFF;
            font-size: 9px;
            content: '\23';
            visibility: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
            font-family: builderboard, sans-serif !important;
            font-style: normal !important;
            font-weight: normal !important;
            font-feature-settings: normal !important;
            font-variant: normal !important;
            text-transform: none !important;
            speak: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            left: 0.4em;
            top: 0.65em;
        }

        &:checked + label::after {
            visibility: visible;
            opacity: 1;
        }

        &:checked + label::before {
            background-color: #B388FF;
            border-color:#B388FF;
        }
    }
}