@import "../../../utils";

.autoCompleteSelectInput {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    flex-grow: 1;

    >.inputWrapper {
        list-style: none;
        width: 100%;

        &::-webkit-details-marker {
            display: none;
        }
    }

    >main {
        width: 100%;
    }

    input {
        padding: 12px 16px;
        width: 100%;
        border-radius: 4px;
        border: 1px solid var(--base-blue-grey-5, #C0C3CE);
        background: var(--0, #FFF);
        color: var(--base-blue-grey-8, #83889e);

        /* Body/SM/Regular */
        font-family: var(--font-family-default);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        /* 142.857% */
        line-height: 20px;
        letter-spacing: 0.14px;

        &:hover {
            color: var(--base-blue-grey-10, #3C3E49);
            border: 1px solid var(--brand-purple-5, #6200ea);
        }

        &:focus {
            outline: 1px solid var(--brand-purple-5, #6200ea);
        }
    }

    &.error {
        input {
            border: 1px solid var(--red-a-5, #d50000);
            color: var(--red-a-5, #d50000);

            &:focus {
                outline: 1px solid var(--red-a-5, #d50000);
            }
        }

        + .selectBox {
            summary {
                color: var(--red-a-5, #d50000);

                svg {
                    path {
                        stroke: var(--red-a-5, #d50000);
                    }
                }
            }
        }
    }


    &-error {
        display: flex;
        align-items: center;
        padding: 0.3rem 16px;
        gap: 10px;
        margin-bottom: -24px;
        width: 100%;
        color: var(--red-a-5, #d50000);
        font-family: var(--font-family-default);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;

        /* 116.667% */
        line-height: 14px;
        letter-spacing: -0.12px;

        svg {
            path {
                stroke: var(--red-a-5, #d50000);
            }
        }
    }

    ul {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        top: calc(100% + 3px);
        width: 100%;
        max-height: 176px;
        overflow-y: auto;
        z-index: 0;
        opacity: 0;
        border-radius: 4px;
        border: 1px solid var(--base-blue-grey-4, #D0D2DA);
        background: #FFF;

        /* Main Shadows/E100 */
        box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
        pointer-events: none;
        transform: translateY(-10px);
        transition-property: opacity, transform, z-index;
        transition-duration: 150ms;
        transition-timing-function: ease-in-out;

        @include scrollbar;

        &.active {
            z-index: 1000;
            opacity: 1;
            pointer-events: auto;
            transform: translateY(0);
        }

        li {
            &.option {
                display: flex;
                padding: 5px;
                align-items: center;
                width: 100%;

                &.active {
                    background: var(--base-purple-1, #f3ebff);
                }

                article {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    gap: 10px;
                    padding: 4px;
                    width: inherit;

                    main {
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        width: calc(100% - 42px);

                        >p {
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                .avatar-section {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                    border-radius: 50%;
                    border: 1px solid var(--gray-1-white, #fff);
                    background: var(--base-purple-3, #7c4dff);
                    color: var(--gray-1-white, #fff)
                }

                .title {
                    color: var(--base-blue-grey-10, #3c3e49);
                    font-family: var(--font-family-default);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;

                    /* 100% */
                    line-height: 14px;
                    letter-spacing: -0.14px;

                    @supports(-moz-appearance: none) {
                        line-height: 1.25;
                    }
                }

                .subtitle {
                    color: var(--base-blue-grey-9, #676b7e);
                    font-family: var(--font-family-default);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;

                    /* 116.667% */
                    line-height: 14px;
                    letter-spacing: -0.12px;
                }

                &-loader {
                    display: flex;
                    padding: 20px;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    img {
                        width: 24px;
                    }
                }

                &-error {
                    display: flex;
                    padding: 20px;
                    gap: 10px;
                    align-items: center;
                    color: var(--red-a-5, #d50000);

                    svg {
                        path {
                            stroke: var(--red-a-5, #d50000);
                        }
                    }
                }

                &-no-suggestions {
                    display: flex;
                    padding: 20px;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 10px;
                    color: var(--base-blue-grey-9, #676b7e);
                    font-family: var(--font-family-default);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 300;

                    /* 116.667% */
                    line-height: 14px;
                    letter-spacing: -0.12px;
                }
            }
        }
    }
}