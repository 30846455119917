.activityLogTab {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 39px;
    }

    &__list {
        margin-top: 21px;
        overflow: hidden auto;
        flex-grow: 1;
        flex-basis: 0;
    }
}