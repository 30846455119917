.loginContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toggle-password-img{
  -webkit-tap-highlight-color: transparent !important;
  outline: none;
  user-select: none;
  
  &:active{
    background: transparent;
  }
}

.auth-header{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .welcome-text{
    font-size:22px;
    font-weight:500;
    line-height:32px;
    color:#3C3E49;
    margin-bottom:64px;
    text-align:center;
    display:flex;

    img{
      width:28px;
      height:28px;
      margin-left:1px;
    }
  }

  &.signup .welcome-text{
    margin-bottom: 26px;
  }

  .socialLogins {
    cursor: pointer;
    margin-left: 74px;
    margin-right: 74px;
    height: 48px;
    width: 216px;
    background: #FFF;
    border-radius: 8px;
    border: 1px solid rgb(179 136 255 / 30%);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    letter-spacing: 0.01em;


    .icon-google {
      margin-right: 24px;
    }

    .sign-in-google {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: rgb(60 62 73 / 100%);
    }
  }

.container-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  }

  .or-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    .or-text {
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing:0.01em;
      color: rgb(192 195 206 / 100%);
    }
  }
}

input::-webkit-credentials-auto-fill-button, input::-webkit-textfield-decoration-container {
  visibility: hidden;
}

input::after{
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 24px; /* adjust based on button size */
  height: 24px;
  background: transparent;
  pointer-events: none; /* Ensures no interference with input */
  z-index: 2;
}

input::-ms-reveal,
input::-ms-clear {
  display: none!important;
}

.formElements { 
  width: 100%;
  margin:auto;
  box-sizing: border-box;
  
  &.email-to-reset-password, &.reset-password{
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .forgot-password-title{
    font-size:22px;
    font-weight:500;
    line-height:32px;
    color:rgb(60 62 73 / 100%);
    text-align:center;
    margin: 0 126.5px 10px;
  }

  .forgot-password-info{
    font-size:18px;
    line-height:24px;
    color:rgb(103 107 126 / 100%);
    text-align:center;
    margin: 0 126.5px 30px;
    display:block;
  }

  &.email-to-reset-password{
    .footersection{
      padding:30px 0 0;
    }

    .actionbtn{
      margin-bottom:30px;

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        user-select: none;
      }
    }
  }

  ul {
    margin: 0 115.5px;
    display:flex;
    flex-direction:column;
    gap:30px;
    padding:0 11px;
    max-width: 1000px;

    li {
      width: 100%;
      list-style-type: none;
      position: relative;

      >div{
        position:relative;
      }

      label {
        font-size: 16px;
        line-height:24px;
        color: rgb(131 136 158 / 100%);
        position:absolute;
        left: 16px;
        pointer-events: none;
        z-index:99;
        transition: .5s all;
        transform: translateY(-50%);
        top: 50%;

        &.active{
          font-size:12px;
          line-height:16px;
          letter-spacing:0.01em;
          transform: translateY(calc(-50% - 12px));
          transition: .5s all;
        }
      }

      input, .input {
        width: 100%;
        height: 56px;
        padding: 30px 16px 12px;
        font-size: 16px;
        line-height: 28px;
        color: rgb(60 62 73 / 100%);
        border: 1px solid rgb(192 195 206 / 100%);
        border-radius: 8px;
        box-sizing: border-box;
        max-width: 1000px;
        
        &[name='password'], &[name='confirmPassword']{
          padding: 30px 47px 12px 16px;
        }

        &[name='name']{
          padding: 30px 60px 12px 16px;
        }
        
        &:focus-within {
          outline: none;
        }  
      }

      input.error {
          outline: none;
          border-color: #d50000;

          // background: #FF00001A;
      }    
    }
  }

  .forgotPasswordContainer {
    margin: 0 126.5px;
    padding: 6px 0;
    position: relative;
    font-size: 14px;
    margin-top:4px;
    max-width: 1000px;

    .forgotPasswordText {
      color: rgb(98 0 234 / 100%);
      font-size: 14px;
      line-height:20px;
      cursor: pointer;
      font-weight: 500;
      position: absolute;
      right: 11px;
      letter-spacing:0.01em;
    }
  }

  .footersection {
    margin: 0 74px; 
    padding: 44px 0 0;

    .actionbtn{
      display:flex;
      justify-content: center;
      position:relative;

      .sign-in-button-wrapper{
        position:relative;

        &.disabled{
            cursor:not-allowed;
        }

        &:hover .warning-tooltip{
            visibility:visible;
            opacity:1;
        }

        .warning-tooltip{
          position:absolute;
          display:block;
          background-color: #3C3E49;
          color:#fff;
          padding:8px 16px;
          opacity:0;
          visibility:hidden;
          transition: .5s all;
          white-space: nowrap;
          border-radius:3px;
          font-size:12px;
          font-weight:400;
          line-height:16px;
          letter-spacing:0.01em;
          top: -50px;
          left: 50%;
          transform: translateX(-50%);

          &.signup{
            width:199px;
            text-align:center;
            box-sizing:border-box;
            top:-47px;
          }

          &.left-to-right{
              top: -52px;
              left: 21px;
          }
                
          &::after{
              position:absolute;
              content: "";
              width: 0;
              height: 0;
              border: 8px solid transparent;
              border-bottom: 0;
              border-top: 8px solid #3C3E49;
              margin-top: 10px;
              bottom:-8px;
              left:calc(50% - 10px);
          }
        }
      }

      .doneButton {
        width: 242px;
        height: 48px;
        font-weight: 500;
        border: none;
        font-size: 14px;
        line-height:20px;
        letter-spacing:0.01em;
        color: rgb(38 38 38 / 100%);
        background: rgb(0 230 118 / 100%);
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding:1px;

        &:disabled {
          opacity: 0.5;
          pointer-events: none;
          cursor: not-allowed;
          user-select: none;
        }

        .loading {
            align-self: center;
            padding-top: 5px;
            padding-bottom: none;
            margin-right: 10px
        }
      }
    }

    .newUser { 
      width: 100%; 
      text-align: center; 
      font-size: 14px;
      line-height: 24px;
      color: rgb(103 107 126 / 100%); 
      margin-top: 32px;
      white-space: nowrap;

      span {
        color: rgb(98 0 234 / 100%);
        cursor: pointer;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.01em;
        margin-left: 7px;
      }
      &.backToLogin{margin-top: 29px}
    }
   
    .termsCondition { 
      padding: 0 0 30px; 
      font-size: 14px;
      text-align:center;
      margin-top:-14px;
      -webkit-tap-highlight-color: transparent !important;

      .styled-checkbox {
        position: absolute;
        opacity: 0;
        background: transparent;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        user-select: none;

        + label {
          position: relative;
          cursor: pointer;
          padding: 0;
          outline: none;
          -webkit-tap-highlight-color: transparent;
          user-select: none;
        }
        
        + label.error::before{
          border:1px solid #D50000;
        }

        + label::before {
          content: '';
          margin-right: 10px;
          display: inline-block;
          vertical-align: text-top;
          width: 16px;
          height: 16px;
          border:1px solid rgb(192 195 206 / 100%);
          border-radius: 2px;
          box-sizing: border-box;
          background:transparent;
          outline: none;
          -webkit-tap-highlight-color: transparent;
          user-select: none;
        }

        &:checked + label::after {
          content: '';
          position: absolute;
          left: 4px;
          top: 7px;
          background: #6200EA;
          width: 2px;
          height: 2px;
          box-shadow: 1.4px 0 0 #6200EA, 2.8px 0 0 #6200EA, 2.8px -1.4px 0 #6200EA, 2.8px -2.8px 0 #6200EA, 2.8px -4px 0 #6200EA, 2.8px -5.4px 0 #6200EA;
          transform: rotate(45deg);
        }
      }

      p {
        display: inline-block;
        position: relative;
        top: -0.5px;
        color: rgb(60 62 73 / 100%);
        font-size:14px;
        line-height:20px;
        letter-spacing: 0.01em;
        
        a{
          color:rgb(98 0 234 / 100%);
        }
      }
    }
  }
  
  &.signupBlock .footersection{
    margin: 0 10px;

    .showError {
      justify-content: center;
      margin-left: -75px;
    }
  }

  .errorMsgBox { 
    display: none;
    margin: 0;

    .error-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url('../../assets/images/toast_icons/info-circle.svg');
      background-size: cover;
      margin-right: 5px;
    }

    .errorMsg {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #d50000;
      margin-top: 2px;
    }

    &.showError {
      display: flex;
      align-items: center;
      margin-top: 6px;
    }
  }

  &.signupBlock { 
    .socialLogins { flex-flow: row; justify-content:center;

      button { max-width: 153px; margin: 0 10px 0 0; 
        &:last-child { margin: 0; }
      }
    }

    .signupInputContainer {
      max-height: 258px;
      overflow: auto;
      gap: 20px;
      scroll-margin: 20px;
      scrollbar-color: #afb2c0 #ecedf0;
      scrollbar-color: var(--base-blue-grey-6,#afb2c0) var(--base-blue-grey-2,#ecedf0);
      scrollbar-gutter: auto;
      scrollbar-width: thin;
      
      &::-webkit-scrollbar-track {
        background-color: #afb2c0;
      }
      
      #loginEmailInput{
        padding-right:65px;
      }
    }
  }

  &.reset-password{
    padding: 0;

    .reset-password-title{
      font-size:22px;
      font-weight:500;
      line-height:32px;
      color:rgb(60 62 73 / 100%);
      margin-bottom:36px;
      text-align:center;
    }

    .actionbtn {
      &.reset-password {
        margin-bottom: 12px;
      }
    }
  }
  
  .name-length-counter, .email-length-counter {
    font-size:12px;
    line-height:16px;
    letter-spacing: 0.01em;
    color:#83889E;
    position:absolute;
    right:10px;
    top:20px;
    display:none;
    
    &.active{
      display:block;
    }
  }
}

.container-warning {
  display: flex;
  gap: 8px;
  margin-top:8px;

  .vertical-line {
    width: 5px;
    background-color: #6200EA;
    border-radius: 10px;
  }

  .spacer {
    height: 10px;
  }

  .list-item {
    display: flex;
    align-items: center;
    gap:4px;
    margin-left:2px;
    
    &.confirmed{
      .confirm-tick{
        path{
          stroke: rgb(0 200 83 / 100%);
        }
      }
      
      .text-warning{
        color: rgb(0 200 83 / 100%);
      }
    }
    
    &.error{
      .text-warning{
        color: #D50000;
      }
    }
  }

  .dot {
    width: 3px;
    height: 3px;
    background-color: black;
    border-radius: 10%;
    margin-right: 10px;
  }

  .text-warning {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: rgb(175 178 192 / 100%);
  }
}

.go-back-container {
  width: 100%;
  justify-content: center;
  display: flex;

  .go-back {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #6200ea;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    font-weight: 500;
    outline: none;
    border: none;
    background: transparent;
  }
}

.link-expire-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;


  .link-expire-text {
    width: 100%;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: rgb(60 62 73 / 100%);
    margin-bottom: 6px;
  }

  .link-expire-info{
    font-size: 18px;
    line-height: 24px;
    text-align:center;
    color: rgb(103 107 126 / 100%);
    margin-bottom: 16px;
  }

}

.emailSentSuccessfullyContainer, .sent-successful-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .sent-successful{
    width:116px;
    margin-bottom:15px;
  }

  .textSendEmailSuccessfully {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: rgb(60 62 73 / 100%);
    margin-bottom:10px;

    &--info{
      font-size:18px;
      line-height:24px;
      text-align:center;
      color:rgb(103 107 126 / 100%);
      margin-bottom:20px;
    }
  }
}

.sent-successful-container {
  .sent-successful{
    width:116px;
    margin-bottom:20px;
  }

  .successful-description {
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    text-align: center;
    color: rgb(60 62 73 / 100%);
    margin-bottom:11px;
    
    &-sub{
      font-size:18px;
      line-height:24px;
      text-align:center;
      color:rgb(103 107 126 / 100%);
      margin-bottom:18px;
    }
  }
  
  .login-button{
    width: 242px;
    height: 48px;
    font-weight: 500;
    border: none;
    font-size: 14px;
    line-height:20px;
    letter-spacing:0.01em;
    color: rgb(38 38 38 / 100%);
    background: rgb(0 230 118 / 100%);
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.eye-open{
  margin-top: 1px;
}

.Toastify__toast-container{
  #auth{
    width:282px;
    height:52px!important;
    border:1px solid #7C4DFF;
    padding: 8px 8px 8px 16px !important;
  }

  .auth_toast{
    &__text{
      font-size:14px;
      line-height:20px;
      letter-spacing: 0.01em;
      font-family:Rubik, sans-serif;
      display:flex;
      align-items:center;
      justify-content: space-between;
    }

    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width:87px;
      background: var(--Interactive-Bg-P3, #7c4dff);
      color: #fff;
      margin-left: 8px;
      padding-inline: 16px;
      border-radius: 8px;
      cursor: pointer;
      box-sizing: border-box;
    }

    &__loader{
      width:17px;
      height:17px;
      margin-right:8px;

      svg{
        vertical-align:top;
      }

      +span{
        margin-right:8px;
      }
    }
  }

  .Toastify__close-button{
    margin-left:5px!important;
  }
}

@media(width <=1365px){
  .formElements{
    ul, .forgotPasswordContainer{
      margin: 0 18%;
    }

    .forgot-password-title{
      margin: 0 18% 10px;
    }

    .forgot-password-info{
      margin: 0 18% 30px;
    }
  }
}

@media(width >= 721px){
  .auth-toast-container.Toastify__toast-container {
    /* stylelint-disable-next-line */
    left: calc(48.243045388% + 25.878477306%)!important;
  }
}

@media(width <=721px){
  .formElements{
    ul, .forgotPasswordContainer{
      /* stylelint-disable-next-line */
      margin: 0 calc(18.402777777778% - 10.5px);
    }

    .forgot-password-title{
      /* stylelint-disable-next-line */
      margin: 0 calc(18.402777777778% - 0.5px) 10px;
    }

    .forgot-password-info{
      /* stylelint-disable-next-line */
      margin: 0 calc(18.402777777778% - 0.5px) 30px;
    }
  }

  .sent-successful{
    width:101px!important;
  }
}

:root {
  --vh: 1vh;
}

@media(width <=576px){
  html.is-safari,
  html.is-safari body {
    height: calc(var(--vh) * 100)!important;
    max-height: 100vh!important;
    overflow: hidden!important;
  }
  
  .formElements{
    .forgot-password-title{
      /* stylelint-disable-next-line */
      margin:0 5.5555555555556% 16px;
    }

    .forgot-password-info{
      /* stylelint-disable-next-line */
      margin: 0 5.5555555555556% 45px;
      text-align: left;
    }

    .name-length-counter, .email-length-counter {
      top:18px;
    }

    ul, .forgotPasswordContainer{
      /* stylelint-disable-next-line */
      margin: 0 calc(5.5555555555556% - 11px);
    }

    ul {
      li {
        input, .input{
          height:48px;
          font-size:16px;
          line-height:20px;
          letter-spacing: 0.01em;
        }

        label{
          font-size:14px;
          line-height:20px;
          letter-spacing:0.01em;
          
          &.active{
            transform: translateY(calc(-50% - 10px));
          }
        }
      }
    }

    .footersection {
      .actionbtn{
        .doneButton{
          width: 90px;
          height: 36px;

          &.loading{
            width: 120px;
          }

          &.reset-email{
            width:163px;
          }
        }

        &.reset-password .doneButton{
          width:158px;
        }
      }

      .termsCondition {
        /* stylelint-disable-next-line */
        margin-left: 5.5555555556%;
        
        > div {
          display: flex;
          align-items: center;
          
          p{
            text-align: left;
          }
        }

        .styled-checkbox + label::before{
          margin-right: 8px;
        }
      }
    }

    .errorMsgBox{
      .errorMsg{
        font-size:14px;
        line-height:16px;
        letter-spacing:0.01em;
      }
    }
  }

  .errorMsgBox {
    &.showError {
      justify-content: flex-start;
      margin-left: 0;
    }
  }

  .sent-successful-container {
    .sent-successful{
      margin-bottom:16px;
    }

    .successful-description {
      margin-bottom:9px;

      &-sub{
        margin-bottom:16px;
      }
    }

    .login-button{
      width: 90px;
      height: 36px;
    }
  }
  
  .signupBlock{
    .footersection{
      padding: 34px 0;
      margin: 0!important;
      
      .termsCondition{
        padding: 0 0 20px;
      }

      .newUser.backToLogin{
        margin-top:21px;
      }
    }
  }

  .auth-header {
    .socialLogins{
      height: 36px;

      .icon-google{
        margin-right: 22px;
      }
    }
    
    &.login .welcome-text{
      margin-bottom: 30px;
    }
    
    &.signup {
      .welcome-text{
        margin-bottom:11px;
        margin-top:78px;
      }
      
      .or-container{
        margin-top:20px;
        margin-bottom:19px;
      }
    }
  }

  .authBaseScreen .Toastify__toast-container{
    left: 50%;
    transform: translateX(-50%);
  }
}

@media(height < 675px){
  .formElements.loginBlock, .formElements.signupBlock, .formElements.email-to-reset-password, .formElements.reset-password{
    height: 100%;
    overflow: auto;
    padding: 0 0 3%;
    
    
    &.signupBlock .signupInputContainer{
      max-height:unset;
      overflow:unset;
    }
  }
}

@media(height < 675px) and (width <= 720px){
  .ScrollbarsCustom-Scroller{
    margin-top:80px;
  }
}

@media(height <= 420px){
  .formElements.reset-password{
    display:unset;
  }
}

@media(height <= 460px){
  .authBaseScreen .leftSide, .authBaseScreen .leftSide-wrapper{
    display:none;
  }
  
  .authBaseScreen .rightSide{
    width:100%!important;
  }
  
  .ScrollbarsCustom-Scroller{
    margin-top:80px;
  }
}

@media(height <= 460px) and (width >= 1365px) {
  .formElements {
    .forgotPasswordContainer, ul {
      margin: 0 auto;
      width: 100%;
      box-sizing:border-box;
    }
  }
}

@media(width >= 2000px){
  .formElements {
    .forgotPasswordContainer, ul:not(.email-to-reset-password-list){
      margin:0 auto;
    }

    ul:not(.reset-password-list){
      margin:0 auto;
    }
    
    &.email-to-reset-password, &.reset-password{
      align-items:center;
    }

    .email-to-reset-password-list, .reset-password-list{
      width:100%;
      box-sizing:border-box;
    }
  }
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY{
  background: rgb(175 178 192 / 100%)!important;
}

.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY{
  background: #ECEDF0!important;
  user-select: none!important;
  width: 2px!important;
}

.ScrollbarsCustom-Content{
  display:flex;
}

