$primary: #6200EA;

.hyperLinkBox {
    padding: 14px 18px;
    background-color: #fff;
    position: absolute;
    display: none;
    border-radius: 8px;
    font-size: 18px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    transition: 100ms all linear;
    z-index: 1;

    &.__is-hovered {
        display: flex;
        box-shadow: 0 0 9px rgba(#000, 0.1);
    }

    input {
        border: 0;
        font-size: 18px;
        outline: 0;
        height: 100%;
    }

    .actionButtons {
        margin-left: 16px;
        display: flex;
        align-items: stretch;
        justify-content: flex-end;

        button {
            background-color: transparent;
            border: 0;
            outline: none;
            font-size: 24px;;
            margin: 0 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 4px;

            em.icon-tick-circle {
                position: relative;
                top: 2px;   
            }

            em.icon-pencil {
                font-size: 36px;
                position: relative;
                top: 5px;
            }

            em.icon-trash {
                position: relative;
                top: 2px;
            }

            &:not(:disabled):hover {
                background-color: #B388FF;
                color: #fff;
            }

            &:disabled {
                opacity: 0.7;
                cursor: not-allowed!important;
            }
        }
    }

    a {
        display: inline-block;
        border-bottom: 1px solid blue;
        text-decoration: none;
        padding-bottom: 2px;
    }
}