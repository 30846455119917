.addTableBtn {
    position: relative;

    &:not(.disabled):hover .tableBox {
        opacity: 1!important;
        visibility: visible!important;
    }

    .haveSubToolbar {
        &:hover, &.active {
            svg path {
                fill: #fff;
            }
        }

        &::after {
            display: none;
        }
    }
}

.tableBox {
    position: absolute;
    left: 34px;
    padding: 26px;
    top: 5px;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &__wrapper {
        background-color: #fff;
        box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
        border-radius: 5px;
        padding: 5px;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        position: relative;

        &::before {
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 3px 4px 3px 0;
            border-color: transparent #fff transparent transparent;
            display: inline-block;
            position: absolute;
            left: -4px;
            top: 10px;
        }
    
        &__text {
            color: #83889E;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            display: inline-block;
            margin-bottom: 6px;
        }
    
        &__items {
            display: flex;
            align-items: flex-start;
            place-content: flex-start flex-start;
            flex-wrap: wrap;
    
            &__item {
                flex: 0 0 24px;
                max-width: 24px;
                width: 24px;
                height: 24px;
                flex-shrink: 0;
                box-sizing: border-box;
                position: relative;
                background-color: #fff;
                border: 0.063rem solid #DFE0E6;
                outline-width: 0;
                white-space: nowrap;
    
                &.--selected {
                    border-color: #B388FF;
                    z-index: 9;
                }
    
                .customTooltip {
                    max-width: unset;
                    transition: unset;
                    padding: 6px 10px;
                    z-index: 99;
                    min-width: 38px;
                    min-height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    line-height: 16px;
                    background-color: #3C3E49;
                    border-radius: 3px;
                    box-sizing: border-box;
                    bottom: 100%;
                    transform: translate(-50%, -4px);
                    user-select: none;
                    pointer-events: none;
    
                    &::after {
                        border-left: 8px solid transparent;
                        border-right: 8px solid transparent;
                        border-top: 8px solid #3C3E49;
                        bottom: -7px;
                    }
                }
    
                &:hover .customTooltip {
                    opacity: 1;
                    visibility: visible;
                }

                &:not([data-row="0"]) {
                    margin-top: -0.063rem;
                }
                
                &:not([data-col="0"]) {
                    margin-left: -0.063rem;
                }
            }
        }
    }

    &.--centered {
        top: 17px;
        transform: translateY(-50%);

        .tableBox__wrapper::before {
            top: 50%;
            transform: translateY(-50%)
        }
    }
}