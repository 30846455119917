@charset "UTF-8";

@font-face {
  font-family: builderboard;
  src: url("../fonts/builderboard.eot");
  src: url("../fonts/builderboard.eot?#iefix") format("embedded-opentype"),
    url("../fonts/builderboard.woff") format("woff"),
    url("../fonts/builderboard.ttf") format("truetype"),
    url("../fonts/builderboard.svg#builderboard") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]::before {
  font-family: builderboard, sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]::before,
[class*=" icon-"]::before {
  font-family: builderboard, sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-search-normal::before {
  content: "\61";
}

.icon-setting::before {
  content: "\62";
}

.icon-import::before {
  content: "\63";
}

.icon-export::before {
  content: "\64";
}

.icon-zoom-out::before {
  content: "\66";
}

.icon-maximize::before {
  content: "\68";
}

.icon-brush::before {
  content: "\69";
}

.icon-roundedrectangle::before {
  content: "\6a";
}

.icon-select::before {
  content: "\6b";
}

.icon-close-circle::before {
  content: "\6c";
}

.icon-comment::before {
  content: "\6d";
}

.icon-sendback::before {
  content: "\6e";
}

.icon-sendfront::before {
  content: "\6f";
}

.icon-danger::before {
  content: "\70";
}

.icon-document-copy::before {
  content: "\71";
}

.icon-star::before {
  content: "\72";
}

.icon-text::before {
  content: "\73";
}

.icon-elipse::before {
  content: "\74";
}

.icon-eraser::before {
  content: "\75";
}

.icon-tick-circle::before {
  content: "\76";
}

.icon-trash::before {
  content: "\77";
}

.icon-magicpen::before {
  content: "\78";
}

.icon-more-square::before {
  content: "\79";
}

.icon-triangle::before {
  content: "\7a";
}

.icon-undo::before {
  content: "\41";
}

.icon-pentool::before {
  content: "\43";
}

.icon-redo::before {
  content: "\44";
}

.icon-rhombus::before {
  content: "\45";
}

.icon-repeat::before {
  content: "\46";
}

.icon-colors-square::before {
  content: "\47";
}

.icon-document::before {
  content: "\48";
}

.icon-eye::before {
  content: "\49";
}

.icon-plus::before {
  content: "\4a";
}

.icon-arrow-squre-left::before {
  content: "\4b";
}

.icon-star-fill::before {
  content: "\4c";
}

.icon-hand-paper-o::before {
  content: "\4d";
}

.icon-pencil::before {
  content: "\42";
}

.icon-down-arrow-icon::before {
  content: "\67";
}

.icon-up-arrow-icon::before {
  content: "\4e";
}

.icon-arrow-down::before {
  content: "\4f";
}

.icon-line::before {
  content: "\50";
}

.icon-vector::before {
  content: "\51";
}

.icon-image::before {
  content: "\52";
}

.icon-play-circle::before {
  content: "\53";
}

.icon-unlock::before {
  content: "\54";
}

.icon-underline::before {
  content: "\55";
}

.icon-lock::before {
  content: "\56";
}

.icon-bold::before {
  content: "\57";
}

.icon-italic::before {
  content: "\58";
}

.icon-alignright::before {
  content: "\59";
}

.icon-alignleft::before {
  content: "\5a";
}

.icon-aligncenter::before {
  content: "\30";
}

.icon-solid-line::before {
  content: "\31";
}

.icon-dashed-line::before {
  content: "\32";
}

.icon-unlock2::before {
  content: "\33";
}

.icon-lock2::before {
  content: "\34";
}

.icon-send-back2::before {
  content: "\35";
}

.icon-send-front2::before {
  content: "\36";
}

.icon-send-21::before {
  content: "\37";
}

.icon-share::before {
  content: "\38";
}

.icon-copy-link::before {
  content: "\39";
}

.icon-activity-log::before {
  content: "\21";
}

.icon-grid::before {
  content: "\22";
}

.icon-right-tick::before {
  content: "\23";
}

.icon-menu-icon::before {
  content: "\24";
}

.icon-element3::before {
  content: "\25";
}

.icon-align-bottom::before {
  content: "\26";
}

.icon-align-horizontally::before {
  content: "\27";
}

.icon-align-left::before {
  content: "\28";
}

.icon-align-vertically::before {
  content: "\29";
}

.icon-align-right::before {
  content: "\2a";
}

.icon-frame::before {
  content: "\2c";
}

.icon-slider-horizontal::before {
  content: "\2f";
}

.icon-slider-vertical::before {
  content: "\3a";
}

.icon-zoom-in-1::before {
  content: "\2d";
}

.icon-zoom-out-1::before {
  content: "\3b";
}

.icon-info-circle::before {
  content: "\3c";
}

.icon-maxmize::before {
  content: "\2b";
}

.icon-arrow-double-point::before {
  content: "\2e";
}

.icon-arrow-left-point::before {
  content: "\3d";
}

.icon-arrow-right-point::before {
  content: "\3e";
}

.icon-zoom-in::before {
  content: "\65";
}

.icon-linked-screen::before {
  content: "\5b";
}

.icon-menu-icon-1::before {
  content: "\5d";
}

.icon-icon-left-2::before {
  content: "\5e";
}

.icon-icon-righ::before {
  content: "\5f";
}

.icon-copy-icon::before {
  content: "\7b";
}

.icon-close-icon::before {
  content: "\7c";
}

.icon-trash-new::before {
  content: "\60";
}

.icon-hierarchy::before {
  content: "\7e";
}

.icon-parallelogram::before {
  content: "\3f";
}

.icon-rhombus-1::before {
  content: "\40";
}

.icon-table::before {
  content: "\5c";
}

.icon-check-mark::before {
  content: "\e000";
}

.icon-pencil-icon::before {
  content: "\7d";
}

.icon-canvas-redesign-help::before {
  content: "\e001";
}

.icon-canvas-redesign-menu::before {
  content: "\e002";
}

.icon-canvas-redesign-share::before {
  content: "\e003";
}

.icon-canvas-redesign-history::before {
  content: "\e004";
}

.icon-canvas-redesign-zoom-out::before {
  content: "\e005";
}

.icon-canvas-redesign-next::before {
  content: "\e006";
}

.icon-canvas-redesign-zoom-in::before {
  content: "\e007";
}

.icon-canvas-redesign-fit-to-screen::before {
  content: "\e008";
}

.icon-canvas-redesign-text::before {
  content: "\e009";
}

.icon-canvas-redesign-frame::before {
  content: "\e00a";
}

.icon-canvas-redesign-rect::before {
  content: "\e00b";
}

.icon-canvas-redesign-redo::before {
  content: "\e00d";
}

.icon-canvas-redesign-table::before {
  content: "\e00e";
}

.icon-canvas-redesign-undo::before {
  content: "\e00f";
}

.icon-canvas-redesign-connector::before {
  content: "\e010";
}

.icon-canvas-redesign-duplicate::before {
  content: "\e011";
}

.icon-canvas-redesign-star::before {
  content: "\e012";
}

.icon-canvas-redesign-text-align-center::before {
  content: "\e013";
}

.icon-canvas-redesign-text-align-left::before {
  content: "\e014";
}

.icon-canvas-redesign-text-align-right::before {
  content: "\e015";
}

.icon-canvas-redesign-unlock::before {
  content: "\e016";
}

.icon-canvas-redesign-export-board::before {
  content: "\e00c";
}

.icon-canvas-redesign-feedback::before {
  content: "\e017";
}

.icon-canvas-redesign-import-images::before {
  content: "\e018";
}

.icon-canvas-redesign-import-user-flow::before {
  content: "\e019";
}

.icon-canvas-redesign-reset-onboarding::before {
  content: "\e01a";
}

.icon-straight-line-icon::before {
  content: "\e01b";
}