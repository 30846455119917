
#toolbar {
    width: 42px;
    box-sizing: border-box;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 12px;
    top: 50%;
    padding: 4px 0;
    background: #FFF;
    border:  0.5px solid  #DFE0E6;
    box-shadow:  0 4px 16px 0 rgb(0 0 0 / 15%);
    border-radius: 5px;
    z-index: 99;
    transition: 200ms all ease-in-out;
    will-change: transform;
    transform: translate(0, -50%);

    &.leftDrawerActive {
      transform: translate(409px, -50%);
    }

    button {
      width: 34px;
      height: 34px;
      border-radius: 3px;
      margin: 0;
      background: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .2s ease-in-out;
      z-index: 11;

      &.disabled, &:disabled {
        background: transparent!important;
        color: rgb(107 106 106 / 50%)!important;
        cursor: not-allowed;
        user-select: none;
        opacity: 1;
      }

      &:hover {
        transition: all .2s ease-in-out;
        background: #B388FF;
        color: #fff;
      }
    }

    img {
      width: 16px;
      vertical-align: middle;
    }

    input {
      margin: 0 2px;
    }

    input:not([type='checkbox']) {
      width: 50px;
    }

    :global(.p-button) {
      margin: 0 1px;
    }

    &.leftToolbarMain {
      .button {
        width: 32px;
        height: 32px;
        border-radius: 3px;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s ease-in-out;
        z-index: 11;

        em, svg, path, circle {
          transition: all .2s ease-in-out;
        }

        &__iconWrapper {
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: none;
          transition: all .2s ease-in-out;
          border-radius: 3px;
        }

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &.disable-mb {
          margin-bottom: 0 !important;
        }

        &__inner {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.disabled, &:disabled {
          background: transparent!important;
          color: rgb(107 106 106 / 50%)!important;
          cursor: not-allowed;
          user-select: none;
          opacity: 1;

          svg {
            path, circle {
              stroke: rgb(107 106 106 / 50%) !important;
            }
          }

          em {
            color: rgb(107 106 106 / 50%)!important;
          }

          &:hover {
            background: transparent;
            color: rgb(107 106 106 / 50%);
          }
        }

        &:hover {
          transition: all .2s ease-in-out;

          .tooltiptext {
            visibility: visible;
            opacity: 1;
            transition: all 0.3s ease-in-out;
          }
        }

        &:hover, &.active {
          background: #B388FF;
          color: #fff;

          em {
            color: #fff;
          }

          .button__iconWrapper:first-child {
            svg path, circle {
              stroke: #fff;
            }
          }
        }

        &:not(.disabled).haveSubToolbar {
          &:hover, &.active {
            background: transparent !important;

            .button__iconWrapper:first-child {
              background: #B388FF;
            }
          }
        }

        em {
          pointer-events: none;
          font-size: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--color-default-text);

          &.icon-select {
            font-size: 26px;
          }

          &.icon-pencil {
            font-size: 32px;
          }

          &.icon-text {
            font-size: 16px;
          }

          &.icon-hand-paper-o, &.icon-roundedrectangle, 
          &.icon-elipse, &.icon-triangle, &.icon-repeat, &.icon-line {
            font-size: 18px;
          }

          &.icon-parallelogram {
            font-size: 26px;
          }

          &.icon-table {
            height: 18px;
          }
        }

        .tooltiptext {
          pointer-events: none;
          font-size: 10px;
          line-height: 12px;
          width: max-content;
          background-color: black;
          color: #fff;
          text-align: center;
          border-radius: 4px;
          padding: 5px 10px;
          position: absolute;
          z-index: 1;
          top: 5px;
          left: calc(100% + 15px);
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease-in-out;

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent black transparent transparent;
          }
        }

        .icon-pen-tool {
          width: 24px;
          height: 24px;
        }

        .icon-hand {
          width: 22px;
          height: 22px;
        }

        &.haveSubToolbar {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: -3.21px;
            bottom: -3px;
            width: 0;
            height: 0;
            border-bottom: 6px solid #000;
            border-left: 6px solid transparent;
          }

          > .tooltiptext {
            top: -37px;
            left: 50%;
            transform: translateX(-50%);

            &::after {
              content: "";
              position: absolute;
              bottom: 10px;
              left: calc(50% - 5px);
              margin-top: 10px;
              border-width: 5px;
              border-style: solid;
              border-color: black transparent transparent;
            }
          }

          .subToolbar:hover + .tooltiptext {
            visibility: hidden;
            opacity: 0;
          }

          .subToolbar {
            position: absolute;
            left: 36px;
            top: -4px;
            padding: 4px;
            border-radius: 0 4px 4px 0;
            background: #FFF;
            box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 10%);
            border-left: 0;
            display: none;
            align-items: center;
            justify-content: center;
            z-index: -1;

            .button {
              width: 30px;
              height: 30px;
              color: #292D32;
              margin-bottom: unset;

              &:not(:last-child) {
                margin-right: 18px;
              }

              &:hover {
                color: #fff;

                svg {
                  path, circle {
                    stroke: #fff;
                  }
                }
              }

              .icon-rulerpen {
                font-size: 32px;
              }

              .icon-rhombus {
                font-size: 20px;
              }

              .icon-parallelogram {
                font-size: 28px;
              }
            }

            &::before {
              content: '';
              position: absolute;
              left: -20px;
              top: 0;
              width: 30px;
              height: 48px;
              background: transparent;
            }

            &.tooltipTop {
              .button {
                .tooltiptext {
                  pointer-events: none;
                  top: -37px;
                  left: 50%;
                  transform: translateX(-50%);
                  visibility: hidden;
                  opacity: 0;

                  &::after {
                    content: "";
                    position: absolute;
                    bottom: 10px;
                    left: calc(50% - 5px);
                    margin-top: 10px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent;
                  }
                }

                &:hover {
                  .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                  }
                }
              }
            }
          }

          &:hover, &.active {
            background: #B388FF;
            color: #fff;
          }

          &:hover {
            .subToolbar {
              display: flex;
            }
          }
        }

        &.slideUp, &.slideDown {
          background-color: #e0d7f1;
          flex-direction: column;

          em {
            position: relative;
            font-size: 19px;

            &.arr1 {
              top: 6px;
            }

            &.arr2 {
              top: -7px;
            }
          }

          &:hover {
            background: #B388FF;
          }

          &.disabled {
            opacity: .25;
            pointer-events: none;
          }
        }
      }

      .customSelectedItem {
        transition: 200ms all;
        cursor: pointer;
        border-radius: 3px;
        background: #F5F5F5;
        box-sizing: border-box;
        display: none;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: #B388FF;
        border: none;

        &.hide {
            visibility: hidden;
            opacity: 0;
            display: none;
        }

        &.show{
            visibility: visible;
            opacity: 1;
            display: flex;
        }
      }

      .separator {
        height: 1px;
        background-color: #b2b2b2;
        width: 40px;
        margin: 13px auto;
      }

      .scrollContainer {
        // max-height: 190px;
        // overflow: hidden;
        // overflow-y: auto;
        // &::-webkit-scrollbar { width: 0 !important }
        // overflow: -moz-scrollbars-none;
        // -ms-overflow-style: none;
      }

      @media screen and (height <= 700px) {
        .button {
          width: 30px;
          height: 30px;

          em {
            font-size: 18px;

            &.icon-select {
              font-size: 22px;
            }

            &.icon-pencil {
              font-size: 28px;
            }

            &.icon-text {
              font-size: 12px;
            }

            &.icon-hand-paper-o, &.icon-roundedrectangle, 
            &.icon-elipse, &.icon-triangle, &.icon-repeat, &.icon-line {
              font-size: 14px;
            }
          }
        }
      }

      @media screen and (height <= 650px) {
        .button {
          width: 23px;
          height: 23px;

          &.haveSubToolbar {
            .subToolbar {
              left: 31px;
              top: -8px;
            }
          }
        }
      }
    }
  }