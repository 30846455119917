@keyframes skeleton-loading {
    0% {
      background-color: hsl(200deg 20% 80%);
    }

    100% {
      background-color: hsl(200deg 20% 95%);
    }
}

.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    border-radius: 10px;
}