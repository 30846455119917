
.modal {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    transition: 200ms ease-in-out;

    &__backdrop {
        background: rgb(192 158 255 / 28%);
        backdrop-filter: blur(10px);
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        user-select: none;
    }

    &__content {
        background-color: #fff;
        box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
        border-radius: 8px;
        width: 650px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 40px;
        max-width: 95%;
        box-sizing: border-box;
    }

    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.24px;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;

        .closeBtn {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
        }
    }

    &__description {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: #3C3E49;
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 40px -8px 0;

        button {
            padding: 16px 24px;
            height: 60px;
            text-align: center;
            border-radius: 8px;
            background-color: #E8E8E8;
            box-sizing: border-box;
            border: none;
            outline: none;
            cursor: pointer;
            margin: 0 8px;
            flex: auto;
            max-width: 50%;
            font-size: 16px;
            font-weight: 500;
            transition: 200ms ease-in-out;

            &:hover {
                box-shadow: 11px 8px 9px rgb(var(--color-apeiros-purple-dark-rgb) 0.07);
            }

            &.delete {
                background: var(--interactive-bg-err, #FF8A80);
            }
        }
    }

    &.--visible {
        visibility: visible;
        opacity: 1;
    }
}
