.contextMenu {
    position: absolute;
    top: 0;
    left: 0;
    background: #FFF;
    box-shadow: 0 6px 18px rgb(0 0 0 / 6%);
    border-radius: 5px;
    width: 185px;
    box-sizing: border-box;
    user-select: none;
    padding: 5px;
    z-index: 10;

    &__button {
        width: 100%;
        padding: 8px 6px;
        cursor: pointer;
        background-color: #fff;
        margin: 0;
        border: none;
        display: flex;
        transition: 200ms all ease-in-out;
        border-radius: 4px;
        color: #000;

        &:hover {
            background-color: #EFEFEF;
        }

        &:disabled {
            background: transparent!important;
            cursor: not-allowed;
            color: rgb(107 106 106)!important;
            user-select: none;
            opacity: 0.5;
        }

        &--text {
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--color-default-text);
        }
    }
}