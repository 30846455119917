.customTooltip {
  font-size: 12px;
  line-height: 12px;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 4px;
  padding: 8px 10px;
  position: absolute;
  z-index: 1;
  max-width: 106%;
  white-space: normal;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  box-sizing: content-box;

  &::after {
    content: "";
    position: absolute;
  }

  &[data-position="top"] {
    bottom: 140%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &[data-position="bottom-left"] {
    top: 140%;
    left: 50%;
    transform: translateX(-20px);

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
      top: -5px;
      left: 20px;
      transform: translateX(-50%);
    }
  }

  &[data-position="bottom"] {
    top: 140%;
    left: 50%;
    transform: translateX(-50%);

    &::after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid black;
      top: -5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  
  &[data-position="left"] {
    top: 0%;
    left: 0%;
    transform: translateX(-120%);

    &::after {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid black;
      top: 50%;
      right: -5px;
      transform: translateY(-50%);
    }
  }

  &[data-position="right"] {
    top: 0;
    right: 0;
    transform: translateX(120%);

    &::after {
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent; 
      border-right:5px solid black; 
      top: 50%;
      left: -5px;
      transform: translateY(-50%);
    }
  }

  &.__hovered {
    opacity: 1;
    visibility: visible; 
    z-index: 999;
  }

  &._v2 {
    padding: 8px 16px;
    gap: 10px;
    width: max-content;
    border-radius: 3px;
    background: var(--base-blue-grey-10, #3C3E49);
    z-index: 1000;
    position: fixed;

    /* 2px/6px 0.12 */
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
    color: var(--basic-white, var(--0, #FFF));

    /* Body/XS/Regular */
    font-family: var(--font-family-default);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    &::after {
      border-width: 8px;
      top: -8px;
      margin-left: -4px;
      transform: translate(0%, 0%);
    }

    &[data-position="top"]::after { border-top-color: var(--base-blue-grey-10, #3C3E49);  }
    &[data-position="left"]::after { border-left-color: var(--base-blue-grey-10, #3C3E49);  }
    &[data-position="right"]::after { border-right-color: var(--base-blue-grey-10, #3C3E49);  }
    &[data-position="bottom"]::after { border-bottom-color: var(--base-blue-grey-10, #3C3E49);  }
    &[data-position="bottom-left"]::after { border-bottom-color: var(--base-blue-grey-10, #3C3E49);  }

    &[data-position="over"] {
      transform: translate(-50%, -50%);

      &::after {
        display: none;
      }
    }
  }
}