
.miniMapItem {  
  .miniMapButton {
    transition: background 0.1s ease-in; 

    &:not(:disabled).active, &:not(:disabled):hover {
      background: var(--Base-Purple-1, #F3EBFF);
    }

    &.active {
      ~ .miniMapWrapper {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  
  .miniMapWrapper {
      position: absolute;
      bottom: 100%;
      right: 0;
      margin-bottom: 8px;
      width: 315px;
      height: 205px;
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
      overflow: hidden;
      border: 1px solid #DFE0E6;
      border-radius: 8px;
      opacity: 0;
      visibility: hidden;
      transition: 100ms all ease-in-out;

      #minimap {
        width: 100%;
        height: 100%;
      }
  }
}