:root{
    --arrow-color: #fff;
}

.tutorial-card {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    user-select: none;
    z-index: 9999;

    &.hidden {
        opacity: 0;
        z-index: -1;
        visibility: hidden;
        top: 999999px;
        left: 999999px;
    }

    &.row {
        flex-direction: row;
    }

    &.column {
        flex-direction: column;

        &.reverse {
            flex-direction: column-reverse;
        }
    }

    &__content {
        width: 397px;
        box-sizing: border-box;
        background: #FFF;
        box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
        border-radius: 5px;
        padding: 16px;
        min-height: 402px;
    }

    &__arrow {
        width: 15px;

        &--inner {
            border-width: 7.5px;
            border-color: #FFF;
            border-style: solid;

            &.left {
                border-color: transparent var(--arrow-color) transparent transparent;
            }

            &.top {
                border-color: transparent transparent var(--arrow-color) transparent;
            }

            &.right {
                border-color: transparent transparent transparent var(--arrow-color);
            }

            &.bottom {
                border-color: var(--arrow-color) transparent transparent transparent;
            }
        }
    }

    &__image {
        width: 100%;
        height: 243px;

        img {
            width: 100%;
            box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
            border-radius: 5px;
            aspect-ratio: 1.5;
        }

        .tutorial-loading-screen{
            height:243px;
            width:100%;
            display:flex;
            align-items:center;
            justify-content:center;
        }
    }

    &__title, &__description {
        font-family: var(--font-family-default);
        font-style: normal;
        color: var(--color-default-text);
    }

    &__title {
        margin: 16px 0;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
    }

    &__description {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
    }

    &__header {
        margin-bottom: 38px;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &--left {
            display: flex;
        }
    }

    &__btn {
        border: none;
        cursor: pointer;
        background: #6200EA;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px 24px;
        gap: 10px;
        font-family: var(--font-family-default);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: #FFF;
        transition: 200ms all ease-in-out;

        &:disabled {
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &__skip {
        border: none;
        cursor: pointer;
        background: transparent;
        font-family: var(--font-family-default);
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: var(--color-default-text);
        margin-left: 24px;
    }
}