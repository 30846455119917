.searchInput {
    &--wrapper {
        box-sizing: border-box;
        width: 219px;
        position: relative;
    }

    &__btn {
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);

        em {
            font-size: 20.5px;
        }
    }

    &__input {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px 10px 45px;
        background: #FFF;
        border-radius: 4px;
        border: none;
        font-family: var(--font-family-default);
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-default-text);
    }

    &__clearBtn {
        position: absolute;
        transform: translateY(-50%);
        right: 6px;
        top: 50%;
        font-size: 17px;

        em {
            position: relative;
            top: 2px;
        }
    }
}

.framesTab{
    .searchInput__input{
        &.searchActive {
            padding-right: 95px!important;
        }
    }
}