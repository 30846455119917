.feedback_modal {
    box-sizing: border-box;
    width: 614px;
    padding: 18px;
    background: #FAFAFA;
    box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
    border-radius: 5px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--font-family-default);
    font-style: normal;
    z-index: 999;
    user-select: none;

    &__header {
        display: flex;
        justify-content: space-between;
        padding: 0 4px 13px;
        border-bottom: 0.5px solid #BFBFBF;
        margin-bottom: 22px;
        align-items: center;
    }

    &--title {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: var(--color-default-text);
    }

    &--closeBtn {
        padding: 0;
        margin: 0;
        background: transparent;
        border: none;
        cursor: pointer;

        em {
            font-size: 21px;
            position: relative;
            top: 2px;
        }
    }

    &__body {
        padding: 0 4px;
    }

    &--text {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--color-default-text);
    }

    &__rating {
        padding: 0 69px;
        margin-top: 27px;

        &--info {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-top: 12px;

            span {
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: var(--color-default-text);
                opacity: 0.7;
                transition: 200ms all ease-in-out;

                &.active {
                    opacity: 1;
                }
            }
        }

        &--actions {
            transition: 200ms all ease-in;
            opacity: 0;
            transform: translateY(-10px);
            visibility: hidden;
            height: 0;
            overflow: hidden;

            &.active {
                height: auto;
                opacity: 1;
                transform: translateY(0);
                visibility: visible;
                overflow: unset;
            }
        }

        &--input {
            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &--stars {
            display: flex;
            justify-content: center;
        }
    }

    &--slider {
        .rc-slider-dot-active, .rc-slider-handle {
            background-color: #B388FF;
            border-color: #B388FF;
            width: 21px;
            height: 21px;
            margin-top: -6px;
            opacity: 1;
        }

        .rc-slider-track {
            background-color: #B388FF;
            height: 9px;
            border-radius: 8px;
        }

        .rc-slider-handle-dragging {
            border-color: #B388FF !important;
            box-shadow: none !important;
        }

        .rc-slider-rail {
            height: 9px;
            background: #EFEFEF;
            border-radius: 8px;
        }

        &.rc-slider-disabled {
            background-color: transparent;
            opacity: 0.5;
            cursor: not-allowed;
        }
    }

    &--submitBtn {
        margin: 0;
        margin-top: 40px;
        border: none;
        cursor: pointer; 
        padding: 19px 47px;
        background: #6200EA;
        box-shadow: 11px 8px 9px rgb(98 0 234 / 7%);
        border-radius: 5px;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #FFF;
        float: right;
        display: flex;
        align-items: center;
        transition: 200ms all ease-in-out;

        &:hover:not(:disabled) {
            transform: translateY(-4px);
            opacity: 0.8;
        }

        &:disabled {
            opacity: 0.5;
        }
    }

    &__overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(192 158 255 / 28%);
        backdrop-filter: blur(10px);
        z-index: 99;
        transition: 200ms all ease-in-out;
    }
}

.feedback_toast {
    border: 1px solid #6200EA !important;
    border-radius: 5px !important;

    &__text {
        font-size: 14px;
        line-height: 17px;
        width: 100%;
        box-sizing: border-box;
        color: var(--color-default-text);
        display: flex;
        align-items: center;
        white-space: nowrap;

        &--main {
            min-width: 202px;
        }
    }

    &__button {
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 16px;
        border-radius: 8px;
        background: var(--Interactive-Bg-P3, #7C4DFF);
        color: #fff;
        margin-left: 21px;
        cursor: pointer;
    }
}