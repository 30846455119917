@import 'https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap';

body {
  margin: 0;
  font-family: Rubik, sans-serif;
  overflow: hidden;

  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Toastify__toast-theme--light {
  background: #F5F5F5 !important;
  border: 0.5px solid #BFBFBF;
  box-shadow: 0 5px 9px rgb(212 212 212 / 50%) !important;
  border-radius: 5px !important;
  font-family: Rubik, sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #3C3E49 !important;
}

.flowchart-toast {
  border-radius: 8px !important;
  background: #FFF !important;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 15%) !important;
  height: auto !important;
  padding: 8px 16px !important;
  min-height: unset;
}

.Toastify__toast-icon {
  width: 36px !important;
  height: 36px !important;
}

.Toastify__toast-container--bottom-right {
  bottom: 5em;
}

:root {
  --color-apeiros-purple: #B388FF;
  --color-apeiros-purple-dark: #6200EA;
  --color-apeiros-purple-dark-rgb: 98, 0, 234;
  --color-default-text: #3C3E49;
  --font-family-default: 'Rubik', sans-serif;
  --color-base-grey-2: #ecedf0;
  --color-base-grey-3: #E8E8E8;
  --color-blue-grey-10: #3C3E49;
  --color-blue-grey-5: #c0c3ce;
  --color-spring-green: #00E676;
  --color-vivid-tangerine: #FF8A80;
  --color-base-grey-9: #262626;
}