.textEditor {
    position: absolute;
    left: 10px;
    top: 10px;
    outline: none;
    font-size: 14px;
    font-family: var(--font-family-default);
    font-style: normal;
    width: 100%;

    input {
        background-color: transparent;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
    }

    &.editorType-curvedLine {
        left: 0;
        top: 0;
        width: auto;

        input {
            background-color: #fff;
            white-space: pre;
        }
    }

    &.editorType-optimizedImage {
        left: 0;
        top: 0;
        font-size: 12px;
    }

    &.editorType-table {
        left: 0;
        top: 0;

        input {
            position: absolute;
            height: 100%;
            text-align: left;
            max-width: 100%;
            font-family: var(--font-family-default);
            font-style: normal;
            border: 3px solid #536DFE;
            background-color: #fff;
            box-sizing: border-box;
        }
    }
}