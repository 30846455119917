.commentItemWrapper {
    margin-bottom: 10px;
    position: relative;

    &__replies {
        background-color: #F8F9FA;
        position:relative;

        .commentItem {
            margin-left: 20px;
            width: calc(100% - 20px) !important;
            padding-right:16px;
        }

        .commentItem:first-of-type{
            padding-top:0;
        }

        &::after {
            content: "";
            left: 18px;
            top: 0;
            height: calc(100% - 10px);
            position: absolute;
            width: 2px;
            z-index: 0;
            background-color: #ccc;
            border-bottom-left-radius: 16px;
            border-bottom-right-radius: 16px;
        }

        .commentItem__header--title{
            font-size:12px;
        }

        .commentItem__header--date{
            font-size:8px;
        }

        .commentItem__header--actions{
            svg{
                width:12px;
                height:12px;
            }
        }

        .commentItem__comment .commentContent{
            font-size:10px;
        }
    }

    > .commentItem {
        margin-bottom: 0;
    }
}