@import "../../utils";

.spinner {
    display: flex;
    align-items: center;

    .animate-spin {
        @include animate-spin;
    }

    .fill {
        &-amber-a4 {
            fill: var(--amber-amber-a-4, #FFC400);
        }

        &-blue-grey-9 {
            fill: var(--blue-gray-9, #676B7E);
        }

        &-cloud-a4 {
            fill: var(--builder-cloud-a-4, #3D5AFE);
        }

        &-green-a4 {
            fill: var(--builder-green-a-4, #00E676);
        }

        &-grey-9 {
            fill: var(--gray-9, #262626);
        }

        &-purple-a4 {
            fill: var(--builder-purple-a-4, #651FFF);
        }
    }
}
