.layers_widget_list_item {
  padding: 4px 8px;
  border-radius: 4px;
  transition: 200ms all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 37px;
  width: 176px;
  box-sizing: border-box;
  
  &:hover {
    background-color: var(--Base-Purple-1, #F3EBFF);

    .list_item_title__edit_btn {
      opacity: 1;
      visibility: visible;
    }
  }

  &--info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 4px;
    width: calc(100% - 16px);

    .list_item_title {
      width: 100%;
      display: flex;
      align-items: center;
      height: 16px;

      &__text {
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        color: var(--Base-Blue-grey-10, #3C3E49);
        text-overflow: ellipsis;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.12px; 
      }
      
      &__edit_btn {
        opacity: 0;
        display: flex;
        align-items: center;
        visibility: hidden;
        transition: 200ms all;
      }
      
      &__confirm_btn {
        display: flex;
        align-items: center;
        outline: none;
      }
      
      &__edit_input {
        max-width: calc(100% - 16px); 
        outline: none;
        background-color: transparent;
        border: none;
        margin: 0;
        color: var(--Base-Blue-grey-10, #3C3E49);
        text-overflow: ellipsis;
        font-family: Rubik, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.12px;
        padding: 0;
      }

      &.editable {
        gap: 4px;
      }
    }

    .list_item_info {
      color: var(--Base-Blue-grey-7, #9B9FB1);
      text-overflow: ellipsis;
      font-family: Rubik, sans-serif;
      font-size: 8px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.08px;
    }
  }

  &--actions {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}