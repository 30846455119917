.maintenancePage {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    background: #fff;

    .contentBox {
        width: calc(100% - 30px);
        max-width: 600px;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }

        h3 {
            font-size: 34px;
            margin: 30px 0 15px;
            color: #000;
            font-family: var(--font-family-default);
        }

        p {
            font-size: 16px;
            color: #000;
            margin: 0;
            line-height: 26px;
        }
    }
}