
.layers_widget_list {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;

  .line-separator {
      width: 100%;
      box-sizing: content-box;
      border: 0.5px solid #f3ebff;
  }
}