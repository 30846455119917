.profileList {
    right: 30px;
    position: absolute;
    width: 225px;
    top: 110%;
    box-sizing: border-box;
    font-family: var(--font-family-default);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    background: #FFF;
    box-shadow: 0 6px 18px rgb(0 0 0 / 6%);
    border-radius: 5px;

    &__title {
        padding: 13px 19px;
        color: var(--color-default-text);
    }

    &__list {
        border: 0.1px solid #5C5C5C;
        border-radius: 5px;
        padding: 0 10px;
        max-height: 176px;
        overflow: hidden auto;

        &.gradient-active {
            background: linear-gradient(180deg, rgb(230 230 250 / 0%) 78.12%, #E7E7FB 100%);
        }
    }

    &__item {
        padding: 13px 9px;
        border-bottom: 1px solid rgb(0 0 0 / 20%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        color: var(--color-default-text);

        &--name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 80%;

            .tooltiptext {
                bottom: unset;
                top: 80%;
                left: 0;
                transform: translateX(0);
                max-width: 90%;
                white-space: normal;
            }

            &:hover {
                .tooltiptext {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}