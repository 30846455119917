
.custom-connection-toast {
    display: flex;
    align-items: center;
    border-radius: 8px !important;
    height: 28px !important;
    background: var(--0, #FFF);

    .custom-connection-toast-msg {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .leadingIcon{
            width: 20px;
            height: 20px;
        }
    }

    &.success {
        border: 1px solid #00C853;
        border-radius: 8px !important;
        height: 28px !important;



        .Toastify__toast-icon {
            width: 21px !important;
            height: 21px !important;
        }

        .Toastify__close-button {
            align-self: center;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-left: 20px;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #3C3E49;
                }
            }
        }
    }

    &.removed {
        border: 1px solid red;
        border-radius: 8px !important;
        height: 28px !important;

        .Toastify__close-button {
            align-self: center;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-left: 20px;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #3C3E49;
                }
            }
        }
    }

    .Toastify__toast-body {
        padding: 0;
    }

    .Toastify__toast-body>div:last-child {
        font-family: var(--font-family-default);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;

        /* 142.857% */
        letter-spacing: 0.14px;
    }
}