.tooltip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 10px;
    width: max-content;
    border-radius: 3px;
    background: var(--base-blue-grey-10, #3C3E49);
    z-index: 1000;
    pointer-events: none;

    /* 2px/6px 0.12 */
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 12%);
    color: var(--basic-white, var(--0, #FFF));

    /* Body/XS/Regular */
    font-family: var(--font-family-default);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    /* 133.333% */
    letter-spacing: 0.12px;

    svg {
        path {
            fill: var(--base-blue-grey-10, #3C3E49);
        }
    }
}