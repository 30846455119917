@import "../../utils";

$primary: #6200EA;

@mixin hover-effect($color, $alpha) {
  box-shadow: 11px 8px 9px rgba($color, $alpha);
}

.requestEditModal {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  width: 620px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  border-radius: 4px;
  background: var(--0, #FFF);

  * {
    box-sizing: border-box;
  }

  > * {
    width: 100%;
  }

  &.loading {
    height: 456px;
    justify-content: center;
    align-items: center;

    >div {
      width: auto;
    }
  }

  header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    h2 {
      flex: 1 0 0;
      align-self: stretch;
      color: var(--base-blue-grey-10, #3C3E49);
      font-family: var(--font-family-default);
      font-size: 22px;
      font-weight:500;
      line-height: 32px;

      /* 160% */
    }

    button {
      display: flex;
      width: 20px;
      height: 20px;
      padding: 2.083px 2.083px 2.084px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: none;
      background: var(--0, #FFF);

      &:hover {
        background: var(--gray-3, #F5F5F5);
      }
    }
  }

  >main {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &--info{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color:#3C3E49;
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: normal;
    display: flex;
    align-items: center;
    gap: 8px;
    
    .user-info{
      font-weight: 500;
      color: #3C3E49;
      
      &:last-of-type{
        text-transform: capitalize;
      }
    }
    
    >div:last-of-type{
      flex: 1;
      width: calc(100% - 48px);
    }
    
    .user-avatar{
      border-radius: 50%;
      background: #7C4DFF;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 500;
    }

    .optional-text{
      color: #9B9FB1;
    }
  }

  &--form {
    display: flex;
    justify-content: flex-end;
    flex-wrap:wrap;
    align-items: center;
    gap: 16px;
    align-self: stretch;

    .textarea-wrapper{
      position: relative;
      border-radius:4px;
      border:1px solid #C0C3CE;
      height:66px;
      width:100%;
      padding:8px 5px 8px 16px;

      textarea{
        outline:none;
        border:none;
        overflow-y:auto;
        width:100%;
        height:100%;
        font-size:16px;
        line-height:24px;
        padding:0 16px 0 0;
        color:#3C3E49;
        resize:none;
        scrollbar-color: var(--base-blue-grey-6, #afb2c0) var(--base-blue-grey-2, #ecedf0);
        scrollbar-gutter: auto;
        scrollbar-width: thin;
      }

      textarea::placeholder{
        font-size:14px;
        color:#83889E;
      }

      textarea::-webkit-scrollbar {
        width: 0.4em;
      }

      textarea::-webkit-scrollbar-track {
        border-radius:40px;
      }

      textarea::-webkit-scrollbar-thumb {
        border-radius:6px;
        background-color: #AFB2C0;
        padding-left:3px;
      }

      &.warning{
        border-color:#D50000;
      }

      .length-indicator{
        position: absolute;
        right: 0;
        bottom: -25px;
        color:#83889E;
        font-size: 14px;
        line-height: 20px;
      }
    }

    .warning-message{
      font-size:14px;
      color:#D50000;
      width:100%;
      margin-top:-8px;
      opacity:0;
      visibility:hidden;

      &.active{
        opacity:1;
        visibility:visible;
      }
    }

    .request-edit-button-wrapper{
      position:relative;

      &.disabled{
        cursor:not-allowed;
      }
    }

    button {
      flex-grow: 0;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.14px;
      height: 44px;
      border-radius:8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 24px;
      color: #262626;
      white-space: nowrap;

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: not-allowed;
        user-select: none;
      }

      &:not(:disabled):hover {
        @include hover-effect(rgba(98, 0, 234, 0.05), 0.03);
      }

      &.request-edit-button {
        position:relative;
        gap: 4px;
        border-radius: 8px;
        background: #00E676;
        transition: 200ms linear;
        width: 140px;
        
        &.approval{
          width: 97px;
        }
      }

      &.cancel-request-edit-button{
        background:#EEE;
        width: 95px;
        
        &.approval{
          width: 83px;
        }

        &:hover{
          box-shadow:none;
          background: var(--interactive-bg-p-2, #F3EBFF);
        }
      }
    }
  }
  
  &--message{
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #3C3E49;
    white-space: break-spaces;
    padding: 12px 16px;
    background: #FBF8FF;
    border-radius: 4px;
    word-break: break-all;
  }
  
  &--permission{
    &-wrapper{
      display: flex;
      align-items: center;
      gap: 24px;
      padding: 8px 0 8px 16px;
    }
    
    &-text{
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #3C3E49;
    }
  }
}