
.members {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;

    .oval {
        width: 32px;
        height: 32px;
        background: #01E676;
        border: 2px solid white;
        border-radius: 100px;
        margin-left: -6px;
        color: var(--color-default-text);
        font-size: 11px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        box-sizing: border-box;

        .oval__name {
            font-size: 14px;
            font-weight: 500;
            align-self: center;
            margin-top: 1px;
        }

        img {
            max-width: 30px;
        }

        &.more {
            background: #DFE0E6;
            color: #000;
            cursor: pointer;
        }

        &:not(.oval_not_hover):hover {
            border-color: #651FFF;

            .tooltiptext {
                visibility: visible!important;
                opacity: 1!important;
            }
        }
    }

    .tooltiptext {
        font-size: 10px;
        line-height: 12px;
        width: max-content;
        background-color: black;
        color: #fff;
        text-align: center; 
        border-radius: 4px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(-40px / 2 + -6px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;
    
        &::after {
            content: "";
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent black;
        }
    }
}