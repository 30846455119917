.activityLogItem {
    box-sizing: border-box;
    width: 100%;
    background: #FFF;
    border-radius: 4px;
    padding: 10px 12px;
    margin-bottom: 13px;

    &:last-child {
        margin-bottom: 0;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &--title {
            display: block;
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            max-width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color: var(--color-default-text);
        }

        &--date {
            display: flex;
            align-items: center;

            span {
                display: block;
                font-family: var(--font-family-default);
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 11px;
                color: #848484;

                &.circleSeperator {
                    width: 2px;
                    height: 2px;
                    border-radius: 50%;
                    background-color: #979797;
                    margin: 0 8px;
                }
            }
        }
    }

    &__content {
        margin-top: 20px;

        &--list {
            list-style-type: disc;
            padding-left: 20px;
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: var(--color-default-text);
        }
    }

    &__listItem {
        padding: 5px 2px;

        &--head {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position:relative;
            gap: 15px;
        }

        &--title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--color-default-text);
        }

        &--headRight {
            display: flex;
            align-items: center;
        }

        &--date {
            margin-right: 0.5rem;

            span {
                display: block;
                font-family: var(--font-family-default);
                font-style: normal;
                font-weight: 400;
                font-size: 9px;
                line-height: 11px;
                color: #848484; 
            }
        }

        &--restoreBtn {
            em {
                position: relative;
                top: 2px;
                font-size: 14px;
            }
        }

        &--itemText {
            display: block;
            border-left: 2px solid rgb(205 204 215 / 100%);
            word-wrap: break-word;
            padding-left: 8px;
            margin-top: 4px;
            font-family: var(--font-family-default);
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            color: rgb(95 92 128 / 100%);
            font-style: italic;
        }

        &:hover {
            background-color: rgb(240 240 243 / 100%);
            cursor: pointer;
        }
    }

    &__wrapper {
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}