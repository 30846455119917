@import "../../utils";

@-moz-document url-prefix() {
    .mention__list-wrap {
        .mention__item {
            width:100%;
        }
    }
}

.mention__list-wrap {
    position: absolute;
    top: 53px;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1;
    left: 0;
    box-sizing: border-box;
    padding:10px 0;

    /* layout */
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;

    /* style */
    border-radius: 4px;
    border: 1px solid var(--Base-Blue-grey-4, #D0D2DA);
    background: #FFF;

    /* Main Shadows/E100 */
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);

    @include scrollbar;

    &.reverse-positioning{
        top:unset;
        bottom:55px;
        z-index:2;
    }

    .no-user-found {
        /* layout */
        display: flex;
        margin: 12.5px 20px;
        flex-direction: column;
        justify-content: center;

        /* typography */
        color: var(--Base-Blue-grey-9, #676B7E);
        font-family: var(--font-family-default);
        font-size: 12px;
        font-style: normal;
        font-weight: 300;

        /* 116.667% */
        line-height: 14px;
        letter-spacing: -0.12px;
    }

    .mention__item {
        display: flex;
        padding: 10px 20px;
        align-items: center;
        width: available;
        width: fill-available;
        width: fill-available;
        box-sizing:border-box;

        &.selected {
            background: var(--base-purple-1, #f3ebff);
        }

        article {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 10px;
            width: inherit;
            pointer-events:none;

            main {
                display: flex;
                flex-direction: column;
                gap: 5px;
                width: calc(100% - 42px);

                >p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .avatar-section {
            display: flex;
            width: 32px;
            height: 32px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: auto;
            border-radius: 50%;
            border: 1px solid var(--gray-1-white, #fff);
            background: var(--base-purple-3, #7c4dff);
            color: var(--gray-1-white, #fff)
        }

        &--name {
            color: var(--base-blue-grey-10, #3c3e49);
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

            /* 100% */
            line-height: 14px;
            letter-spacing: -0.14px;

            @supports(-moz-appearance: none) {
                line-height: 1.25;
            }
        }

        &--email {
            color: var(--base-blue-grey-9, #676b7e);
            font-family: var(--font-family-default);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            /* 116.667% */
            line-height: 14px;
            letter-spacing: -0.12px;
        }
    }

    &.build-card-linked {
        .mention__item {
            article {
                align-items: center;
            }
        }
    }
}

footer .mention__list-wrap {
    top:45px;
    width:calc(100% + 20px);
    margin-left:-10px;

    &.reverse-positioning{
        bottom:50px;
    }
}

.editInput__wrapper{
    .mention__list-wrap {
        top:unset;
        z-index:9;
    }
}
