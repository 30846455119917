.flowchart {
    &--overlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(192 158 255 / 28%);
        backdrop-filter: blur(10px);
        z-index: 99;
        transition: 200ms all ease-in-out;
    }

    &--modal {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 656px;
        box-sizing: border-box;
        z-index: 999;
        border-radius: 8px;
        background: #FFF;
        padding: 40px;
        box-shadow: 0 12px 24px 0 rgb(0 0 0 / 15%);
        user-select: none;

        &__header {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 32px;

            h2 {
                color: #000;
                font-family: var(--font-family-default);
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                letter-spacing: 0.24px;
            }
        }

        &__info {
            margin-top: 16px;
            color: var(--color-blue-grey-10);
            font-family: var(--font-family-default);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        &__btn {
            padding: 0;
            margin: 0;
            cursor: pointer;
            transition: 200ms all ease-in-out;
            border: none;
            background: none;

            &.mainBtn {
                display: flex;
                height: 60px;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                flex: 1 0 0;
                border-radius: 8px;
                background: var(--color-base-grey-3);
                color: var(--color-base-grey-9);
                font-family: var(--font-family-default);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;

                &.importBtn {
                    background: var(--color-spring-green);
                }

                &.removeBtn {
                    background: var(--color-vivid-tangerine);
                }
            }
        }

        &__footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 16px;
            margin-top: 40px;
        }

        &.loadingState {
            display: grid;
            grid-template-columns: 1fr auto;
            column-gap: 32px;

            .flowchart_closeBtn {
                align-self: baseline;
            }
        }

        &__loader {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
}

.flowchart-toast {
    display: flex;
    align-items: center;

    &.success {
        border: 1px solid #00C853;

        .Toastify__toast-icon {
            width: 21px !important;
            height: 21px !important;
        }

        .Toastify__close-button {
            align-self: center;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-left: 20px;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: #3C3E49;
                }
            }
        }
    }

    &.removed {
        border: 1px solid #651FFF;

        .flowchart-toast-msg {
            display: flex;
            align-items: center;
        }

        .flowchart_toast_action_btn {
            border-radius: 8px;
            background: #7C4DFF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.14px;
            color: #fff;
            padding: 16px 24px;
            border: none;
            margin: 0 0 0 16px;
            height: 36px;
            box-sizing: border-box;
            cursor: pointer;
        }
    }

    .Toastify__toast-body {
        padding: 0;
    }

    .Toastify__toast-body > div:last-child {
        font-family: var(--font-family-default);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        letter-spacing: 0.14px;
    }
}