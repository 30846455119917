.lineOption {
    &__wrapper {
        position: relative;
    }

    &__list {
        padding: 4px 8px;
        display: grid;
        grid-template-columns: 1fr;
        row-gap: 12px;
        box-sizing: border-box;
        position: absolute;
        bottom: 45px;
        border-radius: 5px;
        background: #FFF;
        box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
        left: 50%;
        transform: translateX(-50%);

        &::after {
            content: "";
            position: absolute;
            bottom: -8px;
            border-width: 4px;
            border-style: solid;
            border-color: #fff transparent transparent;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &__item {
        width: 100px;
    }

    &--thicknessSliderWrapper {
        box-sizing: border-box;
        padding: 0 4px;
    }

    &--thicknessSlider {
        .rc-slider-dot {
            width: 3px;
            border-radius: 5px;
            background-color: #DFE0E6;
            border: 1px solid #DFE0E6;
        }

        .rc-slider-rail {
            background-color: #DFE0E6;
        }

        .rc-slider-dot-active, .rc-slider-handle {
            background-color: var(--color-apeiros-purple);
            border-color: var(--color-apeiros-purple);
        }

        .rc-slider-track {
            background-color: var(--color-apeiros-purple);
        }

        .rc-slider-handle {
            opacity: 1;
        }

       .rc-slider-handle-dragging {
            border-color: var(--color-apeiros-purple) !important;
            box-shadow: none !important;
        }
    }

    &__buttonWrapper {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;

        .lineOption__button {
            width: 46px;
        }

        &.has_two_buttons {
            justify-content: space-between;

            .lineOption__button {
                width: 46px !important;
            }
        }

    }

    &__button {
        padding: 4px;
        transition: 200ms all;
        cursor: pointer;
        border-radius: 3px;
        background: #F5F5F5;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: none;
        border: none;

        &.hide {
            visibility: hidden;
            opacity: 0;
            display: none;
        }

        &.show{
            visibility: visible;
            opacity: 1;
            display: flex;
        }

        em {
            font-size: 24px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            transition: 200ms all;
        }

        &.active, &:not(:disabled):hover {
            background: #B388FF!important;
            color: #fff;

            svg {
                path {
                    stroke: #fff;
                }
            }
        }

        &:disabled .button svg{
            opacity: 0.5!important;
            cursor: not-allowed;
        }
    }

    &__lasso {
        padding: 4px;
        transition: 200ms all;
        cursor: pointer;
        border-radius: 3px;
        background: #F5F5F5;
        box-sizing: border-box;
        display: none;
        justify-content: center;
        align-items: center;
        margin: 0;
        background-color: none;
        border: none;

        &.hide {
            visibility: hidden;
            opacity: 0;
            display: none;
            height: 0;
        }

        &.show{
            visibility: visible;
            opacity: 1;
            display: flex;
        }

        em {
            font-size: 24px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        svg {
            transition: 200ms all;
        }

        &.active, &:not(:disabled):hover {
            background: #B388FF!important;
            color: #fff;

            svg {
                path {
                    stroke: #fff;
                }
            }
        }

        &:disabled .button svg{
            opacity: 0.5!important;
            cursor: not-allowed;
        }
    }
}