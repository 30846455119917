@mixin badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    border: 1.5px solid #fff;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    color: #fff;


    &.success {
        background: var(--Base-Green-4, #00E676);
    }

    &.error {
        background: var(--Red-A5, #D50000);
    }

    &.default {
        background: var(--Blue-Gray-4, #D0D2DA);
    }

    &.information {
        background: var(--Builder-Cloud-A4, #3D5AFE);
    }

    &.warning {
        background: var(--Amber-Amber-A4, #FFC400);
    }
}

@mixin scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--base-blue-grey-6, #afb2c0) var(--base-blue-grey-2, #ecedf0);
    scrollbar-gutter: auto;
    scroll-margin: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: var(--base-blue-grey-2, #ecedf0);
  }

  &::-webkit-scrollbar-thumb {
    width: 2px;
    border-radius: 40px;
    background: var(--base-blue-grey-6, #afb2c0);
  }
}

@mixin animate-spin {
  animation: spin 1s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border-width: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}