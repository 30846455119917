#canvasContainer {
  border: dotted gray thin;
}

.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 999;
  background: #ffffffb3;
}

.loaderWrapper {
  width: 43px; height: 60px; display: flex; position: relative;
  animation: spinAnimation linear 3s infinite;
}

.loadingAnimation {
  width: 100%; 
  height: 100%; 
  display: flex; 
  overflow: hidden; 
  position: absolute; 
  z-index: 1; 
  top:0; 
  left:0; 
  animation: 
  loadingAnimation ease-in-out 3s infinite;

    @keyframes loadingAnimation {
      0%, 20% {height: 100%;}
      25%, 40% {height: 80%;}
      45%, 60% {height: 50%;}
      65%, 80% {height: 20%;}
      85%, 100% {height: 0;}
    }

    @keyframes spinAnimation {
      0% {transform: scale(1) rotate(0);}
      10% {transform: scale(0.5) rotate(180deg);}
      20%, 100% {transform: scale(1) rotate(360deg);}
    }
}

#root {
  overflow: hidden;
  position: relative;
}

.whiteboardContainer {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .whiteboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fafafa;
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  
    .canvas-container {
      overflow: hidden;
    }

    &.smallDotBg {
      background: url("../../assets/images/small_dot_bg.png");
    }

    &.bigDotBg {
      background: url("../../assets/images/big_dot_bg.png");
    }

    &.solidWhiteBg {
      background: #fff;
    }

    .commentFormWrapper {
      position: absolute;
      left: 0;
      top: 0;
      will-change: transform;
      display: none;
    }

    .commentWrapper--wrap {
      position: absolute;
      left: 0;
      top: 0;
      will-change: transform;
      display: none;
      z-index: 70;
    }
  }
}

:global(button) {
  padding: 12px;
  margin: 1px;
  background-color: #7b7de0;
  border-radius: 3px;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    background-color: #7173ee;
  }
}

.dropdownButton {
  background-color: #7b7de0;
  color: white;
  padding: 11px 14px;
  font-size: 14px;
  border: none;
}

.uploadDropdown {
  position: relative;
  display: inline-block;

  input {
    display: none;
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  z-index: 1;
}

.dropdownContent span {
  display: block;
  color: black;
  padding: 12px 16px;
  cursor: pointer;
}

.dropdownContent a:hover {
  background-color: #ddd;
}

.uploadDropdown:hover .dropdownContent {
  display: block;
}

.uploadDropdown:hover .dropdownButton {
  background-color: #7173ee;
}

.siteOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgb(192 158 255 / 28%);
  backdrop-filter: blur(10px);
  z-index: 99;
  transition: 200ms all ease-in-out;
}

.grayOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  background-color: rgb(82 82 82 / 60%);
  opacity: 0;
  transition: 200ms all ease-in-out;

  &.active {
    opacity: 1;
  }
}

#text-copy-input {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
}

.toast-container.Toastify__toast-container--bottom-right {
  &.minimap-opened {
    bottom: 280px;
  }
}

.custom-toast {
  border: 1px solid #6200EA !important;
  border-radius: 5px !important;
}

.pageWrapper {
  &.hidePage {
    display: none;
  }

  &__loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    height: 100%;
    display: grid;
    z-index: 99;
    place-items: center;
    opacity: 0.7;
  }
}

textarea[data-fabric-hiddentextarea] {
  position: fixed!important;
}

.wb_toast__access-requests{
  border: 1px solid var(--Brand-Purple-4, #651FFF)!important;
}

.access-requests_toast{
  &__text{
    display:flex;
    align-items:center;
    white-space: nowrap;

    &--main{
      span{
        font-weight:500;
      }
    }
  }

  &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width:81px;
      background: var(--Interactive-Bg-P3, #7c4dff);
      color: #fff;
      padding-inline: 16px;
      border-radius: 8px;
      cursor: pointer;
      box-sizing:border-box;
      margin-left:35px;
    }
}