.mainDiv {
    width: 100vw;
    height: 100vh;

    .iframeCls{
        overflow:hidden;
        overflow:hidden hidden;
        height:100%;
        width:100%;
        position:absolute;
        inset:0
    }
}