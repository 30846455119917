.feedback_modal__text-input-wrapper {
    user-select: none;

    .tooltipText {
        font-size: 10px;
        line-height: 12px;
        width: max-content;
        background-color: black;
        color: #fff;
        text-align: center; 
        border-radius: 4px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        top: calc(-40px / 2 + -10px);
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        &::after {
            content: "";
            position: absolute;
            bottom: -9px;
            left: 50%;
            transform: translateX(-50%);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: black transparent transparent;
        }
    }

    .feedback_text_header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;

        &--title {
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            color: var(--color-default-text);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-right: 16px;
        }

        &--count {
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: var(--color-default-text);
        }
    }

    .feedback_modal__textInput {
        position: relative;

        &--textarea {
            border: 1px solid #DFE0E6;
            background: #FAFAFA;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 8px 16px;
            font-family: var(--font-family-default);
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            resize: none;
            margin: 4px 0 0;
            min-height: 120px;

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &--attachBtn {
            position: absolute;
            right: 4px;
            bottom: 4px;
            border-radius: 50%;
            display: grid;
            align-items: center;
            padding: 8px;
            background: transparent;
            border: none;
            cursor: pointer;
            transition: 200ms all ease-in-out;

            &:hover:not(:disabled) {
                box-shadow: rgb(0 0 0 / 35%) 0 5px 15px;

                .tooltipText {
                    visibility: visible;
                    opacity: 1;
                }
            }

            &:disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &--attachFile {
            position: absolute;
            left: -999px;
            top: -999px;
            width: 1px;
            height: 1px;
        }
    }

    .feedback_modal__files {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        &--item {
            padding: 7px 6px;
            background: #EFEFEF;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: var(--color-default-text);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 6px;
            max-width: 200px;

            &:not(:first-child) {
                margin-left: 6px;
            }

            &-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &-remove {
                color: #6200EA;
                position: relative;
                top: 2px;
                border: none;
                background: transparent;
                padding: 0;
                margin: 0 0 0 15px;
                cursor: pointer;

                &:hover {
                    .tooltipText {
                        visibility: visible;
                        opacity: 1;
                    }
                }
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }
}