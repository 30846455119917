.numberInput {
    display: flex;
    align-items: center;

    &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
    }
      
      
      .custom-dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 170px;
        overflow:hidden scroll;
        margin-top: 10px;
        background-color: #fff;
        border-top: none;
        scrollbar-width: none;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgb(0 0 0 / 10%);
        z-index: 1;
        padding: 0 4px;
        text-align: center;
        box-sizing: border-box;
      }

      .custom-dropdown::-webkit-scrollbar {
        width: 2px; 
      }
      
      .custom-dropdown::-webkit-scrollbar-thumb {
        background-color: #C7C7C7; 
        border-radius: 2px;
 
      }
      
      
      .custom-option {
        padding: 8px 0;
        font-size: 12px;
        color: #333;
        margin: 5px 0;
        border-radius: 3px;
        cursor: pointer;
        background-color: #f0f0f0;
        transition: background-color 0.3s;
      }

      .custom-option.disabled {
        background-color: grey;
        cursor: not-allowed;
      }

      .custom-option.selected {
        background-color: #B388FF;
        color: #fff;
      }

    input {
        text-align: center;
        max-width: 30px;
        font-family: var(--font-family-default);
        padding: 0;
        font-size: 14px;
        min-width: 30px;
        display: block;
        color: #050038;
        border: none;
        margin-right: 2px;
    }

    input:focus {
        outline: none;
        border: none;
    }

    input[type="number"]{
        appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button { 
        appearance: none; 
    }

    &__buttons {
        display: flex;
        flex-direction: column;
    }

    &__btn {
        display: flex;
        border: 0;
        background-color: transparent;
        color: #000;
        cursor: pointer;

        svg {
            width: 10px;
            height: 5px;
        }

        padding: 0;
        margin: 0;

        &.btn_sizeDown {
            align-items: flex-start;
            height: 50%;
            padding-top: 5px;
        }

        &.btn_sizeUp {
            align-items: flex-end;
            height: 50%;
            padding-bottom: 5px;
        }
    }
}