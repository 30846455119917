.container {
    z-index: 100;

    .control-result {
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0 10px;
        box-sizing: border-box;
        position: absolute;
        top: 0;
        right: 0;

        .text-result {
            margin-right: 10px;
            font-size: 12px;
            color: #BFBFBF;
            margin-top: 1px;
        }

        .text-search {
            font-size: 12px;
            color: #000;
        }

        .divider {
            width: 1px;
            height: calc(100% - 20px);
            background-color: #BFBFBF;
            margin-right: 10px;

            // margin-top: 10px;
            // margin-bottom: 10px;
        }



        .icon-up {
            margin-top: 2px;
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-down {
            margin-top: 2px;
            margin-right: 10px;
            cursor: pointer;
        }

        .icon-close {
            margin-top: 2px;
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .expandable-container {
        width: 320px;
        height: 36px;
        border: 1px solid rgb(179 136 255 / 30%);
        background: #FFF;
        border-radius: 8px;
        top: 0;
        right: 0;
        transition: width 0.3s ease;

        &.minimized {
            width: 36px;
            height: 36px;
            border: 1px solid rgb(179 136 255 / 30%);
            box-sizing: border-box;
            background: #FFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .icon-container {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            padding: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                color: #fff;
                font-size: 13px;
            }
        }

        input[type='text'] {
            margin-bottom: 5px;
            margin-top: 4px;
            margin-left: 5px;
            width: 62%;
            color: var(--color-default-text);
            height: calc(100% - 10px);
            border: none;
            font-size: 14px;

            &:focus {
                outline: none;
                border-color: transparent;
            }

            &::placeholder {
                color: var(--Base-Blue-grey-10, #3C3E49);
            }
        }

        .button-container {
            display: flex;
            justify-content: space-between;

            button {
                font-size: 14px;
            }
        }
    }
}