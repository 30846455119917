.request{
    &--wrapper{
        display:flex;
        align-items:center;
        justify-content:center;
        width: 100svw;
        height: 100svh;
        background:#FBF8FF;
    }

    &--container{
        display:flex;
        flex-direction:column;
        align-items:center;
        gap:20px;

        &.access{
            .request{
                &--backToDashboard{
                    font-size:14px;
                    font-weight:500;
                    line-height:20px;
                    color:#6200EA;
                    display:flex;
                    align-items:center;
                    gap:8px;
                    cursor:pointer;
                }
            }
        }
    }

    &--logo{
        position: absolute;
        left:28px;
        top:28px;
        width:188px;
        height:24px;
        cursor:pointer;
    }

    &--access-text{
        font-size:20px;
        font-weight:500;
        line-height:32px;
        margin-top:5px;
        color:#3C3E49;
    }

    &--board-owner-text{
        font-size:16px;
        line-height:24px;
        margin-top:15px;
        color:#676B7E;
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        gap:5px;
        max-width: 434px;

        span:last-child{
            font-size:18px;
            font-weight:500;
            color: #3C3E49;
            word-wrap:break-word;
            max-width:434px;
        }
    }

    &--textarea-wrapper{
        position:relative;
        padding:36px 5px 12px 16px;
        margin-top:30px;
        height:90px;
        width:434px;
        box-sizing:border-box;
        border-radius:8px;
        border:1px solid #C0C3CE;
        background:#fff;
        cursor:text;

        .length-indicator{
            position: absolute;
            right: 0;
            bottom: -25px;
            color:#83889E;
            font-size: 14px;
            line-height: 20px;
        }

        textarea{
            resize:none;
            height:100%;
            max-height:100%;
            width:100%;
            background:transparent;
            border:none;
            outline:none;
            display:flex;
            justify-content:center;
            align-items:center;
            color:#3C3E49;
            font-size:14px;
            line-height:20px;
            box-sizing:border-box;
            overflow-y:visible;
            padding:0 16px 0 0;
            scrollbar-color: var(--base-blue-grey-6, #afb2c0) var(--base-blue-grey-2, #ecedf0);
            scrollbar-gutter: auto;
            scrollbar-width: thin;

            &::placeholder{
                font-size:14px;
                color:#83889E;
            }
        }

        textarea::-webkit-scrollbar {
            width: 0.4em;
        }

        textarea::-webkit-scrollbar-track {
            border-radius:40px;
        }

        textarea::-webkit-scrollbar-thumb {
            border-radius:6px;
            background-color: #AFB2C0;
            padding-left:3px;
        }

        span:first-of-type{
            position:absolute;
            color:#83889E;
            top:calc(50% - 8px);
            left:16px;
            pointer-events:none;
            font-size:14px;
            line-height:20px;
            transition: .5s all;

            &.active{
                top:calc(50% - 28px);
                font-size:10px;
                transition: .5s all;
            }
        }
    }

    &--button{
        align-items: center;
        border-radius: 8px;
        display: flex;
        height: 36px;
        width: 157px;
        justify-content: center;
        padding: 16px 24px;
        transition: .2s linear;
        background: var(--interactive-bg-p-3, #7c4dff);
        color: var(--interactive-label-p-3, #fff);
        border: none;
        cursor: pointer;
        flex-grow: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: .14px;
        line-height: 20px;
    }

    &--accountOwner-text{
        color:#676B7E;
        font-size:14px;
        line-height:20px;
        margin-top:65px;
        display:flex;
        justify-content:space-between;
        align-items: center;
        width:100%;
        max-width: 434px;

        >div{
            display: flex;
            align-items: flex-start;
            gap:5px;
            flex-wrap: wrap;
            max-width: calc(100% - 120px);
        }

        &-mail{
            font-weight:500;
            max-width:316px;
            word-wrap:break-word;
        }

        &-switchAccount{
            position:relative;
            color:#6200EA;
            text-decoration: underline;
            cursor:pointer;
            margin-left:16px;

            .warning-tooltip{
                position:absolute;
                display:block;
                background-color: #3C3E49;
                color:#fff;
                padding:8px 16px;
                opacity:0;
                visibility:hidden;
                transition: .5s all;
                white-space: nowrap;
                border-radius:3px;
                top:-43px;
                left:57px;
                font-size:12px;
                line-height:16px;
                
                &::after{
                    position:absolute;
                    content: "";
                    width: 0;
                    height: 0;
                    border: 8px solid transparent;
                    border-bottom: 0;
                    border-top: 8px solid #3C3E49;
                    margin-top: 10px;
                    bottom:-8px;
                    left:6px;
                }
            }
            
            &:hover{
                .warning-tooltip{
                    visibility:visible;
                    opacity:1;
                    transition:.5s all;
                }
            }
        }
    }

    &--access-successful{
        height:101px;
    }
}