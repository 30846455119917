.commentInput {
    width: 308px;
    display: block;
    position: relative;
    
    &--btn {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .2s ease-in-out;
        font-size:22px;
        display:flex;
        cursor: pointer;

        &.disabled{
            cursor:not-allowed;
        }

        em{
            display:flex;
        }

        >div{
            height:22px;
        }

        svg {
            width:22px;
            height:22px;

            path {
                stroke: var(--blue-gray-7, #9B9FB1);
            }
        }

        .tooltiptext {
            font-size: 10px;
            line-height: 12px;
            width: max-content;
            background-color: black;
            color: #fff;
            text-align: center; 
            border-radius: 4px;
            padding: 5px 10px;
            position: absolute;
            z-index: 1;
            left: -50%;
            bottom: calc(-40px / 2 + -6px);
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
  
            &::after {
                content: "";
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
                margin-top: -5px;
                border-width: 5px;
                border-style: solid;
                border-color: transparent transparent black;
            }
        }

        &:hover {
            .tooltiptext {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .input-container {
        border: 1px solid;
        border-color:var(--blue-gray-2,#ecedf0);
        border-radius: 4px;
        width: 308px;
        height: 50px;
        position: relative;
        background: var(--base-blue-grey-1,#f8f9fa);

        &.disabled{
            cursor:not-allowed;
        }

        input { 
            border: none;
            position: absolute;
            inset: 0;
            z-index: 1;
            outline: none;
            padding: 0 0.5rem 0 18px;
            font-size: 14px;
            background: transparent;
            width:77%;
            height:100%;

            &:disabled{
                cursor:not-allowed;
            }
        }

        input:not([value=""]) {
            -webkit-text-fill-color: transparent;
        }

        .input-renderer {
            font-size: 14px;
            position: absolute;
            inset: 0;
            display: flex;
            align-items: center;
            margin: 0 0.5rem 0 18px;
            white-space: pre;
            overflow-x: auto;
            user-select: none;
            scrollbar-width: none;
            width:77%;
            height:100%;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .highlight-purple {
            color: #651FFF;
        }

        &.active{
            border-color: var(--interactive-button-bg-p-3, #7C4DFF);
            background: #fff;

            ~ button svg {
                path {
                    stroke: var(--interactive-button-bg-p-3, #6200EA);
                }
            }
        }
    }
}

footer .commentInput{
    &--btn {
        right:10px;
        font-size:12px;

        svg{
            width:16px;
            height:16px;
        }

        >div{
            height:16px;
        }
    }

    .input-container {
        width: 250px;
        height: 2rem;
        background: var(--base-blue-grey-1,#f8f9fa);

        input {
            padding: 0 0.5rem;
            font-size: 12px;
            width:82%;
        }

        .input-renderer {
            font-size: 12px;
            margin: 0 0.5rem;
            width:82%;
        }
    }
}