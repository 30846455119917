@import "../../../utils";

@mixin collaboratorAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: 2px solid #fff;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}

#collaborators-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: relative;
    margin-right: 20px;
    transition: 0.3s ease;

    .avatar-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: nowrap;
        
        .avatar {
            @include collaboratorAvatar;

            position: relative;

            &:not(:last-child) {
                margin-right: 4px;
            }

            .customTooltip {
                min-width: max-content;
                text-transform: none;
            }

            &:hover .customTooltip {
                opacity: 1;
                visibility: visible; 
            }
        }
    }

    .more-collaborators {
        .count {            
            @include collaboratorAvatar;

            font-size: 12px;
            color: #3C3E49;
            font-weight: 500;
            background-color: #DFE0E6;
            margin-left: 4px;
            cursor: pointer;

            &.__active {
                border-color: #651FFF;
            }
        }

        .list {
            width: 180px;
            height: auto;
            max-height: 240px;
            border-radius: 8px;
            border: 1px solid #ECEDF0;
            box-shadow: 0 2px 4px 0 #00000026;
            overflow-y: auto;
            position: absolute;
            right: 0;
            top: 40px;
            background-color: #fff;
            box-sizing: border-box;

            @include scrollbar;

            .list-item {
                padding: 12px 0 12px 16px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;
                box-sizing: border-box;
                max-width: 100%;

                .avatar {
                    @include collaboratorAvatar;

                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                    border: 0;
                }

                .user-name {
                    font-size: 12px;
                    white-space: nowrap;
                    color: #3C3E49;
                    position: relative;
                }
            }

            &:not(.__active) {
                display: none;
            }
        }
    }
}