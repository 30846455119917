.layers_widget_add_input {
  padding: 0 12px;

  &--input {
    outline: none;
    width: 100%;
    padding: 4px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid var(--Base-Purple-3, #B388FF);
    background: var(--Base-Purple-0, #FBF8FF);
  }
}