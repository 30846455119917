.dropdown {
    position: relative;

    &__button {
        border: none;
        background-color: none;
        outline: none;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }

}

.dropdownMenu {
    background-color: #fff;
    position: absolute;
    min-width: max-content;
    width: 100%;
    transform: translateX(-100%);
    z-index: 9999;
    top: 0;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 8px 21px rgb(0 0 0 / 15%);
    overflow: hidden;

    &__item {
        padding: 12px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        user-select: none;
        height: 40px;
        box-sizing: border-box;

        .menu-item-label {
            font-size: 12px;
            line-height: 16px;
            color: #3C3E49;
            letter-spacing: 0.12px;
        }

        .icon {
            margin-right: 8px;
            height: 14px;
        }

        &.--disabled {
            cursor: not-allowed;
            pointer-events: none;            
            opacity: 0.25;
        }

        &:hover {
            background-color: #eee;
        }
    }
}