
.html-editor-wrapper {
    position: fixed;
    width: 0;
    height: 0;
    left: 0;
    top: 0;
    transform-origin: center center;
    color: rgb(60 62 73 / 100%);
    backface-visibility: hidden; /* Helps in anti-aliasing */
    font-size: 0;

    .html-editor {
        width: 100%;
        height: 100%;
        overflow: hidden;
        box-sizing: border-box;
    
        .ql-container {    
            .ql-editor {
                align-content: center;
                align-items: center;
                font-size: inherit;
                line-height: inherit;
                scrollbar-width: none; /* For Firefox */
                text-align: unset;
                padding: 0!important;
                font-kerning: none;
                font-variant-ligatures: none;
                overflow-x: hidden;

                p {
                    span {
                        font-size: inherit!important;
                    }
                }

                &::-webkit-scrollbar {
                    display: none; /* For Chrome, Safari, and other WebKit browsers */
                }
            }
    
            .ql-tooltip {
                visibility: hidden;
            }
        }    
    }

    .actions {
        z-index: 9;

        button {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            background-color: unset;
            padding: 0;
            border: 0;
            cursor: pointer;
            box-shadow: 0 12px 30px 0 rgb(101 31 255 / 10%);
            border-radius: 50%;
            overflow: hidden;
            pointer-events: auto!important;
            user-select: auto!important;

            &.up-btn {
                bottom: 100%;
                transform-origin: bottom center;
            }

            &.down-btn {
                top: 100%;
                transform-origin: top center;
            }

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }

    &[data-is-edit="false"] {
        cursor: move;

        .ql-editor {
            cursor: move;

            p {
                cursor: inherit;
            }

            a {
                cursor: pointer!important;
            }
        }
    }

    &[data-text-overflow="true"] {
        .html-editor {
            .ql-container {
                .ql-editor {
                    align-content: baseline;
                }
            }
        }
    }
}