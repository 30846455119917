.layers_widget {
  width: 200px;
  height: 364px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 4px 12px 8px;
  background: var(--Base-Purple-0, #FBF8FF);
  box-shadow: 0 2px 4px 0 rgba(0 0 0 / 15%);
  position: fixed;
  left: 10%;
  top: 20%;
  user-select: none;
  display: grid;
  grid-row-gap: 8px;
  grid-template-rows: 16px 20px 1px auto 1px 16px;
  z-index: 11;
  transition: 200ms transform ease-in-out;

  &.leftDrawerActive{
    transform: translateX(409px);
  }

  button {
    border: none;
    padding: 0;
    margin: 0;
    background: none;
    cursor: pointer;
  }
  
  &__scrollable_header {
    display: flex;
    justify-content: center;
    align-items: center;

    .dragger_img {
      cursor: move;
    }
    
  }
  
  &__header {
    display: flex;
    justify-content: space-between;

    h2 {
      color: var(--color-default-text);
      font-family: Rubik, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.14px;
    }
  }
  
  &__separator {
    width: calc(100% + 24px);
    height: 1px;
    margin-left: -12px;
    margin-right: -12px;
    background: var(--Base-Blue-grey-3, #DFE0E6);
  }
  
  &__content {
    width: calc(100% + 24px);
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    flex-direction: column;
    
    &--empty_widget {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .title {
        color: var(--Base-Blue-grey-7, #9B9FB1);
        font-family: Rubik, sans-serif;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.12px;
      }

      button {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        place-items: center;
        color: var(--Interactive-Bg-Purple, #6200EA);
        font-family: Rubik, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.14px;
      }
    }

    .layers_content_separator {
      width: 100%;
      height: 1px;
      background: var(--Base-Purple-1, #F3EBFF);
    }
    
    .ScrollbarsCustom-Content {
      padding: 0 12px !important;
    }

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
      width: 4px !important;
      right: 2px !important;
    }

    .ScrollbarsCustom-Scroller{
      margin-top: 0;
    }
  }
  
  &__footer {
    display: flex;
    justify-content: space-between;

    .confirmButton {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      color: var(--Base-Purple-6, #6200EA);
      font-family: Rubik, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.1px;

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  #layersDraggableElement  {
    left: 100%;
    top: 100%;
    position: fixed;
    width: 200px;
    box-sizing: border-box;
    padding: 4px 8px;
    background: var(--Base-Purple-0, #FBF8FF);
    border-radius: 4px;
    border: 1px solid var(--Base-Purple-4, #7C4DFF);
    font-family: Rubik, sans-serif;
    pointer-events: none;
    z-index: 999999999;
  }
}