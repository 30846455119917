@import "../../utils";

$primary: #6200ea;
$primaryLight: rgb(98 0 234 / 5%);
$backgroundColor: #fafafa;

@mixin hover-effect($alpha) {
  box-shadow: 11px 8px 9px rgba($primary, $alpha);
}

.whiteboardWrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 100vh;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  .whiteboardSidebar {
    flex: 0 0 239px;
    max-width: 239px;
    height: 100vh;
    position: relative;
    transition: 200ms linear all;
    background-color: #fff;

    .sidebarInnerWrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: space-between;
      padding: 14px;
      height: 100%;
      box-sizing: border-box;
      overflow-x: hidden;

      .logoWrapper {
        margin-top: 4px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        img {
          width: 188px;
          height: 24px;
        }
      }
      
      .navigationMenuWrapper{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }

      .navigationMenu {
        margin-top:173px;

        .navigationBtnWrapper {
          margin-bottom: 14px;

          .navigationBtn {
            width: 100%;
            height: 50px;
            border-radius: 9px;
            border: none;
            background-color: transparent;
            color: var(--color-default-text);
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            position: relative;

            .icon {
              align-items: center;
              display: flex;
              margin-left: 6px;
              margin-right: 14px;

              &.isCollapse {
                width: 64px;
                margin-left: 20px;
                margin-right: 20px;
              }
            }

            .upDownIcon {
              position: absolute;
              right: 31.25px;
              top: 50%;
              transform: translateY(-50%);
              margin-top: 2px;
              transition: opacity 0ms linear 200ms;

              svg {
                transform: rotate(90deg);
                transition: 300ms ease-in-out;
              }
            }

            .name {
              transition: opacity 0ms linear 200ms;
              opacity: 1;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.14px;
            }

            &.hasSubmenu {
              background-color: $backgroundColor;
            }

            &.active,
            &:hover {
              background-color: white;
              color: $primary;

              &.RECENT .icon path,
              &.PROJECTS .icon path,
              &.TEMPLATES .icon path {
                fill: $primary;
              }

              &.ALL_CANVASES .icon path,
              &.MY_CANVASES .icon path,
              &.SHARED_WITH_ME .icon path,
              &.TRASH .icon path,
              &.FAVORITES .icon path {
                stroke: $primary;
              }

              .upDownIcon svg path {
                stroke: $primary;
              }
            }

            &.active {
              .upDownIcon {
                svg {
                  transform: rotate(270deg);
                }
              }
            }
          }

          .submenu {
            padding-left: 60px;
            background: linear-gradient(0deg, rgb(0 0 0 / 20%) -12.5%, rgb(0 0 0 / 0%) 5.18%), $backgroundColor;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding-top: 8px;
            padding-bottom: 16px;

            .submenuItem {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: nowrap;
              font-weight: 500;
              font-size: 16px;
              background-color: transparent;
              outline: none;
              border: none;
              cursor: pointer;
              height: 64px;

              span {
                margin-left: 48px;
              }

              em {
                font-size: 20px;
                margin-top: 3px;
              }

              &:hover {
                color: $primary;

                svg path {
                  stroke: $primary;
                }
              }
            }

            .addProjectBtn {
              width: calc(100% - 20px);
              height: 44px;
              background-color: $primary;
              border: none;
              border-radius: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              color: white;
              font-size: 14px;
              cursor: pointer;
              font-weight: 500;

              em {
                margin: 6px 11px 0 -8px;
                font-size: 24px;
                font-weight: normal;
              }
            }
          }
        }
      }

      .logoutBtn {
        border: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        box-sizing: content-box !important;
        margin-left: 6px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
        width: 100%;
        height: 64px;
        border-radius: 9px;
        transition: 200ms linear box-shadow;
        z-index: 2;

        .icon {
          margin: 20px;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          cursor: pointer;
          background-color: transparent;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
          font-size: 16px;

          &:hover {
            @include hover-effect(0.07);
          }
        }

        .avatar {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          min-width: 32px;
          background-color: $primary;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          user-select: none;
          font-size: 16px;

          &.isCollapse {
            margin-left: 15px;
            margin-right: 15px;
          }
        }

        .iconLogout {
          position: absolute;
          right: 20px;
          transition: opacity 0ms linear 200ms;
          opacity: 1;
          cursor: pointer;
        }

        span {
          margin-top: 3px;
          transition: opacity 0ms linear 200ms;
        }

        .userName {
          margin-left: 8px;
          width: 100%;
          max-width: 115px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-right: 56px;
          transition: opacity 0ms linear 200ms;
          opacity: 1;
          color: var(--blue-grey-10, var(--base-blue-grey-10, #3c3e49));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: 0.14px;
          overflow-y: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }

        // &:hover {
        //   @include hover-effect(0.07);
        // }
      }
    }

    .sidebarCollapseExpandBtn {
      background-color: #fff;
      border: none;
      outline: none;
      position: absolute;
      right: -8px;
      top: 39px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      transition: 200ms linear all;
      z-index: 2;

      img {
        transition: 200ms linear all;
      }

      &:hover {
        @include hover-effect(0.07);
      }
    }

    &.__is-collapsed {
      flex: 0 0 102px;
      max-width: 102px;

      .sidebarCollapseExpandBtn {
        img {
          transform: rotate(180deg);
        }
      }

      .sidebarInnerWrapper {
        .logoWrapper {
          h1 {
            opacity: 0;
            transition: opacity 0ms linear 0ms;
          }
        }

        .navigationMenu {
          .navigationBtn {
            .name {
              transition: opacity 0ms linear 0ms;
              opacity: 0;
            }

            .upDownIcon {
              transition: opacity 0ms linear 0ms;
              opacity: 0;
            }
          }
        }

        .logoutBtn {
          span,
          .userName,
          .icon,
          .iconLogout {
            transition: opacity 0ms linear 0ms;
            visibility: hidden;
            opacity: 0;
          }
        }
      }
    }
  }

  .whiteBoardContent {
    background-color: $backgroundColor;
    width: 100%;
    height: inherit;
    padding-left: 20px;

    .contentHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 20px;

      h3 {
        font-size: 24px;
        font-weight: 400;
        color: var(--color-default-text);
      }

      .contentHeaderRight {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .searchField {
          width: 44px;
          height: 44px;
          position: relative;
          margin-right: 20px;
          box-sizing: border-box;
          border-radius: 5px;
          transition: 300ms ease-in-out;

          button {
            background-color: transparent;
            border: none;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            padding-top: 11.25px;
            padding-bottom: 11.25px;
            font-size: 20.5px;
            width: 44px;
            z-index: 9;
            cursor: pointer;
            color: #000;
          }

          input {
            width: 100%;
            height: 100%;
            border: none;
            padding-left: 44px;
            outline: none;
            background-color: $primaryLight;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 5px;
            color: var(--color-default-text);
            font-size: 16px;
            font-weight: 400;

            /* stylelint-disable */
            &::placeholder,
            &:input-placeholder,
            &::input-placeholder {
              color: var(--color-default-text);
              opacity: 1;
            }
            /* stylelint-enable */
          }

          &.__is-expanded {
            width: 286px;
          }
        }

        .addNewBoardBtn {
          width: 133px;
          height: 48px;
          background: var(--interactive-bg-p-1, #00e676);
          border: none;
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          font-size: 14px;
          cursor: pointer;
          margin-right: 40px;
          transition: 200ms linear box-shadow;


          .title {
            color: var(--interactive-label-p-1-p-2-e-1, #262626);
            margin-left: 8px;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: 0.14px;
          }

          // &:hover {
          //   @include hover-effect(0.15);
          // }
        }
      }
    }

    .contentArea {
      margin-top: 20px;
      height: calc(100vh - 108px);
      overflow: hidden scroll;
      padding-bottom: 60px;
      background-color: #fff;
      margin-right: 20px;
      border-radius: 8px;

      @include scrollbar;

      .typeOfBoard {
        margin-left: 36px;
        margin-top: 24px;
        color: #000;
        font-family: var(--font-family-default);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 32px;
      }

      .info-text-wrapper{
        display:flex;
        align-items:center;
        gap:8px;
        margin:8px 36px 20px;

        span{
          font-size:14px;
          line-height:20px;
          color:#676B7E;
        }
      }

      .boardList {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        overflow-y: hidden;
        margin-left: 28px;
        margin-right: 28px;

        .boardCardWrapper {
          flex: 1 1 280px;
          min-height: 320px;
          max-height: 320px;
          min-width: 250px;
          max-width: 250px;
          padding: 12px;
          box-sizing: content-box;

          .boardCard {
            width: 100%;
            position:relative;
            cursor: pointer;
            transition: 200ms linear;

            .cardThumbnail {
              background: var(--purple-gradients-2, linear-gradient(135deg, #F4EEFC 0%, #FAEEEC 100%));
              display: flex;
              align-items: center;
              justify-content: center;
              height: 112px;
            }

            .cardDetails {
              background-color: #fff;
              display: flex;
              padding: 16px;
              border-left: 1px solid var(--blue-grey-3, #dfe0e6);
              border-right: 1px solid var(--blue-grey-3, #dfe0e6);
              flex-flow: column nowrap;
              align-items: flex-start;
              justify-content: space-between;

              .owner-div {
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 100%;
                white-space: nowrap;
              }

              .sub-title {
                color: var(--Santas-Gray, #9b9fb1);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
              }

              .sub-value {
                color: var(--Bright-Gray, #3c3e49);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0.12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .cardDetailsLeft {
                line-height: 19px;
                max-width: 100%;
                position: relative;

                strong {
                  color: var(--blue-grey-10, var(--base-blue-grey-10, #3c3e49));
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 500;
                  display: block;
                  max-width: 216px;
                  line-height: 24px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .boardNameTooltip {
                  font-size: 12px;
                  line-height: 12px;
                  width: max-content;
                  background-color: black;
                  color: #fff;
                  text-align: center;
                  border-radius: 4px;
                  padding: 8px 10px;
                  position: absolute;
                  z-index: 1;
                  top: 140%;
                  left: 0;
                  visibility: hidden;
                  text-overflow: ellipsis;
                  opacity: 0;
                  transition: all 0.3s ease-in-out;
                  box-sizing: content-box;
                  max-width: calc(100% - 5px);
                  white-space: break-spaces;
                  word-wrap: break-word;

                  &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: -10px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent black;
                  }
                }

                .boardDescription {
                  font-size: 16px;
                  color: rgb(0 0 0 / 50%);
                  font-weight: 500;
                  display: block;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  line-height: 19px;
                }
              }

              // .star {
              //   font-size: 20px;
              //   color: #FFE600;
              // }
            }

            .line {
              width: 100%;
              border-bottom: 1px solid var(--blue-grey-3, #dfe0e6);
            }

            .cardBottom {
              border-left: 1px solid var(--blue-grey-3, #dfe0e6);
              border-right: 1px solid var(--blue-grey-3, #dfe0e6);
              border-bottom: 1px solid var(--blue-grey-3, #dfe0e6);
              border-radius: 0 0 0 20px;
              padding: 16px;
              padding-left: 24px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              flex-wrap: nowrap;

              .access {
                font-size: 16px;
                font-weight: 400;
                color: rgb(0 0 0 / 50%);
                visibility: hidden; // Temporarily hidden. Will open after feature implemented by backend
              }

              .members {
                padding-right: 0;
                margin-left: 0;
              }
            }

            .more-menu-button{
              .more-icon{
                height:16px;
              }

              em::before{
                font-size:12px;
              }
            }

            &:hover:not(.more-menu-button:hover) {
              // @include hover-effect(0.07);

              .boardNameTooltip {
                visibility: visible !important;
                opacity: 1 !important;
              }
            }

            &.trash-list::before{
              content: ""; /* Create a pseudo-element */
              position: absolute; /* Position the pseudo-element absolutely */
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              background-color: #fff;
              opacity: 0.48;
              z-index:99;
              cursor:not-allowed;
            }
          }
        }
      }
    }
  }

  .listing-loading-screen{
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    width:100%;
  }
}

.delete-board-modal{
  .modal{
    &__content{
      width:470px;
      padding:24px;
    }

    &__title{
      font-size:20px;
      line-height:32px;
      margin-right:28px;
      overflow-wrap: anywhere;

      button{
        position:absolute;
        top:24px;
        right:18px;
      }
    }

    &__description{
      font-size:14px;
      line-height:20px;

      span{
        span{
          font-weight:500;
        }
      }
    }

    &__actions{
      justify-content:flex-end;
      margin: 32px -8px 0;
      
      button{
        height:36px;
        flex:none;
        font-size:14px;
        line-height:20px;
        padding:0;

        &:first-child{
          width:95px;
        }

        &:last-child{
          width:93px;
        }
      }
    }
  }

  #boardsDeleteBtn {
      background-color: #FF8A80;
  }
}

.board-toast-container{
  #duplicate-success{
    width:470px;
    height:52px!important;
  }

  .wb_toast__duplicate-board {
      border: 1px solid var(--Brand-Purple-4, #651fff);
      padding: 8px 11px 8px 16px !important;
  }

  .duplicate-board_toast{
    &__text{
      font-size:14px;
      line-height:20px;
      font-family:Rubik, sans-serif;
      display:flex;
      align-items:center;
    }

    &__button{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width:166px;
      background: var(--Interactive-Bg-P3, #7c4dff);
      color: #fff;
      margin-left: 8px;
      padding-inline: 16px;
      border-radius: 8px;
      cursor: pointer;
    }

    &__loader{
      width:17px;
      height:17px;
      margin-right:8px;

      svg{
        vertical-align:top;
      }

      +span{
        margin-right:8px;
      }
    }
  }

  .Toastify__close-button{
    margin-left:5px!important;
  }

  #duplicate-loading{
    .Toastify__close-button{
      svg{
        width:18.5px;
        height:18.5px;
      }
    }
  }

  .wb_toast__delete-board {
    button{
      margin-left:20px!important;
    }
  }
}

@media (height <= 800px){
  .navigationMenu{
    margin-top: 100px!important;
  }
}
