@import "../../../utils";

.commentItem {
    box-sizing: border-box;
    display: flex;

    // width: 255px;
    // width:100%;
    width: calc(100% - 20px);
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;

    // gap: 8px;

    // margin-right: -6px;

    border-radius: 4px;
    background: var(--blue-gray-1, #F8F9FA);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;

        &--title {
            /* typography */
            color: var(--blue-gray-10, #3C3E49);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            /* Body/SM/Medium */
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            /* 142.857% */
            letter-spacing: 0.14px;
        }

        &--left {
            /* layout */
            display: flex;
            width: 115px;
            align-items: center;
            gap: 4px;

            .badge {
                @include badge;
            }
        }

        &--right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 12px;
            flex-shrink: 0;

            &.float-right {
                grid-template-columns: auto;
            }
        }

        &--date {
            color: var(--blue-grey-7, #9B9FB1);

            /* Body/2XS/Regular */
            font-family: var(--font-family-default);
            font-size: 10px;
                font-style: normal;
                font-weight: 400;

            /* 160% */
            line-height: 16px;
        }

        &--actions {
            button {
                em {
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

    &__comment {
        width: 100%;

        .commentContent {
            /* layout */
            align-self: stretch;

            /* typography */
            color: var(--basic-black, #000);
            font-feature-settings: 'clig'off, 'liga'off;
            font-family: var(--font-family-default);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            /* 166.667% */
            line-height: 20px;
            word-break: break-word;
            margin-right:28px;
        }

        footer {
            /* layout */
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;

            /* typography */
            color: var(--blue-grey-7, #9B9FB1);

            /* Body/2XS/Regular */
            font-family: var(--font-family-default);
            font-size: 10px;
            font-style: normal;
            font-weight: 400;

            /* 160% */
            line-height: 16px;

            &.flex-start {
                justify-content: flex-start;
            }

            &.space-between{
                justify-content: space-between;
            }

            .resolved {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 4px;

                svg{
                    min-width:14px;
                }
            }

            .toggleReplyBtn {
                /* typography */
                color: var(--Base-Purple-5, #651FFF) !important;

                /* Body/XS/Link */
                font-family: var(--font-family-default);
                font-size: 12px;
                font-style: normal;
                font-weight: 400;

                /* 133.333% */
                line-height: 16px;
                letter-spacing: 0.12px;
                text-decoration-line: underline;
            }
        }

        &--mention {
            color: var(--base-purple-5, #651FFF);
        }
    }

    &__skeleton {
        box-sizing: border-box;
        width: 100%;
        background: #FFF;
        border-radius: 4px;
        padding: 10px 12px;
        margin-bottom: 6px;

        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--comment {
            margin-top: 20px;
        }
    }
}

.commentOptionsMenu {
    /* layout */
    display: flex;
    max-width: 300px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    z-index: 100;

    /* style */
    border-radius: 8px;
    border: 1px solid var(--base-blue-grey-2, #ECEDF0);
    background: var(--0, #FFF);

    /* Main Shadows/E100 */
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    overflow:hidden;

    button {
        all: unset;

        /* layout */
        display: flex;
        padding: 12px 16px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        /* style */
        background: var(--0, #FFF);
        cursor: pointer;

        /* typography */
        color: var(--blue-grey-10, var(--base-blue-grey-10, #3C3E49));

        /* Body/XS/Regular */
        font-family: var(--font-family-default);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;

        /* 133.333% */
        line-height: 16px;
        letter-spacing: 0.12px;


        &.disabled {
            opacity: .25;
            pointer-events: none;
        }

        &:hover {
            background: var(--blue-gray-2, #ECEDF0);
        }
    }
}
