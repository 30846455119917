@import "../../../utils";

.commentWrapper {
    /* layout */
    display: inline-flex;
    padding: 8px 0;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 275px;

    /* style */

    border-radius: 4px;
    border: 1px solid var(--blue-gray-2, #ECEDF0);
    background: #FFF;

    /* DS small */
    box-shadow: 0 2px 6px 0 rgb(0 0 0 / 10%);

    .divider {
        all: unset;
        background: var(--blue-gray-2, #ECEDF0);
        height: 1px;
        width: 100%;
    }
}

@mixin commentToolbar {
    display: flex;
    width: 243px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
}

.commentToolBar {
    @include commentToolbar;

    &--colors {
        display: flex;
        align-items: center;

        &.disabled {
            div {
                cursor: not-allowed;
                user-select: none;
                pointer-events: none;
                opacity: .5;
            }
        }
    }

    &--wrapper {
        padding:2px;
        border-radius:4px;
        border:1px solid transparent;
        box-sizing:border-box;
        z-index: 2;
        margin: 0 -1px;
        transition: all .2s ease-in-out;
        cursor:pointer;

        &.selected{
            &.blue{
                border-color:#00A8FF;
            }

            &.orange {
                border-color: #F90;
            }

            &.red {
                border-color: #F00;
            }

            &.green {
                border-color: #4BB43A;
            }

            &.purple {
                border-color: var(--interactive-bg-p-3, #7C4DFF);
            }

            &.yellow {
                border-color: #FFD64D;
            }
        }
    }

    &--colorBtn {
        // all: unset;
        width: 1rem;
        height: 1rem;
        border-radius: 2px;
        z-index: 1;
        transition: all .2s ease-in-out;

        &.colorBtn {
            &--blue {
                background-color: #00A8FF;
            }

            &--orange {
            background-color: #F90;
            }

            &--red {
            background-color: #F00;
            }

            &--green {
            background-color: #4BB43A;
            }

            &--purple {
            background-color: var(--interactive-bg-p-3, #7C4DFF);
            }

            &--yellow {
                background-color: #FFD64D;
            }
        }
    }

    &--options {
        display: flex;
        width: 42px;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
        z-index: 2;
    } 

    &--optionsBtn {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &.resolveBtn {
            margin-right: 12px;
            height:16px;
            position: relative;

            &:hover {
                .tooltipText {
                    opacity: 1;
                    visibility: visible;
                }
            }

            .tooltipText {
                font-size: 10px;
                line-height: 12px;
                width: max-content;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 4px;
                padding: 5px 10px;
                position: absolute;
                z-index: 1;
                left: -18px;
                top: calc(2px + 100%);
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease-in-out;
      
                &::after {
                    content: "";
                    position: absolute;
                    top: -3px;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent black;
                } 
            }

            &.disabled {
                cursor: not-allowed;
                user-select: none;
            }
        }
    }

    &__skeleton {
        @include commentToolbar;

        .skeleton {
            width: 20px;
            height: 20px;
            border-radius: 3px;
        }

        &--colors {
            display: flex;
            align-items: center;
            gap: 4px;
        }

        &--options {
            display: flex;
            align-items: center;
            z-index: 1; 

            .skeleton {
                margin-right: 0;
        }
        }
    }
}

  
.commentMoreOption {
    position: relative;
    height:16px;

    .commentMoreOptionsBlock {
        position: absolute;
        top: -4px;
        visibility: hidden;
        opacity: 0;
        transition: .2s ease-in-out;
        transition-property: transform, opacity, visibility;

        &.commentMoreFixed {
            position: fixed;
        }

        &.commentHeaderOption {
            left: 25px;
        }

        &.commentItemOption {
            right: -145px;
        }

        .commentMoreOptionsInner {
            width: 140px;
            margin-left: 13px;
            height: auto;
            background: #fff;
            border-radius: 4px;
            padding: 4px;

            .commentMoreOptionsInner__item {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 36px;
                padding: 0 6px;
                border-bottom: solid 1px rgb(191 191 191 / 30%);

                span {
                    font-family: var(--font-family-default);
                    color: var(--color-default-text);
                    font-weight: 400;
                    font-size: 16px;
                }

                em {
                    color: #000;
                    font-size: 24px;
                }

                &:hover {
                    border-radius: 4px;
                    background: #EFEFEF;
                }

                &:last-child {
                    border: none;
                }

                &.disabled {
                    cursor: not-allowed;
                    user-select: none;
                    background-color: #fff !important;

                    em, span {
                        color: rgb(107 106 106)!important;
                    }
                }
            }
        }
    }

    &:hover {
        .commentMoreOptionsBlock {
            visibility: visible;
            opacity: 1;
            transform: translateX(5px);
        }
    }
}

.btnCommentAction {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.commentDetail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width:100%;

    .commentAddForm {
        width: 255px;
    }

    &__skeleton {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 10px;

        .commentAddForm {
            &__skeleton {
        box-sizing: border-box;
                width: 100%;
                border-radius: 4px;
                padding: 10px 12px;
                margin-bottom: 6px;
    }
        }
    }

}

::-webkit-scrollbar {
    width: 1px;
}

::-webkit-scrollbar-thumb{
    background-clip:content-box;
}

.commentList {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    // margin-right: -6px;
    width:100%;
    max-height: 356px;
    overflow:hidden auto;

    // padding:0 10px;
    // box-sizing:border-box;
    @include scrollbar;
}