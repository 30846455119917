.button {
  &.haveMorePopup {
    position: relative;

    .popup{
      visibility: hidden;
      opacity: 0;
      position: absolute;
      left: calc(100% + 5px);
      top: 0;
      border-radius: 8px;
      border: 1px solid #F5F5F5;
      background:  #FFF;
      box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
      z-index: 10;
      border-left: 0;
      display: none;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      width: 180px;
      user-select: none;

      .popupTitle{
        color: #83889E;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        padding: 12px 16px 0;
      }

      .popupInfo {
        color: var(--color-default-text);
        font-size: 11px;
        width: 100%;
        padding: 12px 16px;
        box-sizing: border-box;
        word-break: break-word;
      }

      .item{
        display: flex;
        width: 144px;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-left: 2px;
        padding: 12px 16px;

        .moreTitle{
          color:  #3C3E49;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          margin-left: 8px;
        }

        &:hover {
          border-radius: 4px;
          background: #EFEFEF;
        }

        &.disabled {
          opacity: .5;

          &:hover {
            background: transparent;
          }
        }

        em {
          font-size: 16px !important;
          color: var(--color-default-text) !important;
        }
      }

      &.open {
        visibility: visible;
        opacity: 1;
        display: flex;
      }

      @media screen and (height <= 700px) {
        left: calc(100% + 7px);
      }

      @media screen and (height <= 650px) {
        left: calc(100% + 11px);
      }
    }

    &:hover, &.active {
      background: #B388FF;
      color: #fff;

      em {
        color: #fff !important;
      }
    }
  }
}