@import "../../utils";

$primary: #6200EA;

@mixin hover-effect($color, $alpha) {
    box-shadow: 11px 8px 9px rgba($color, $alpha);
}

.inviteModal {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    width: 620px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 4px;
    background: var(--0, #FFF);

    * {
        box-sizing: border-box;
    }

    > * {
        width: 100%;
    }

    &.loading {
        height: 456px;
        justify-content: center;
        align-items: center;

        >div {
            width: auto;
        }
    }

    header {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 32px;
        align-self: stretch;
        margin-bottom: 16px;

        h2 {
            flex: 1 0 0;
            align-self: stretch;
            color: var(--base-blue-grey-10, #3C3E49);

            /* Body/XL/Medium */
            font-family: var(--font-family-default);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;

            /* 160% */
        }

        button {
            display: flex;
            width: 20px;
            height: 20px;
            padding: 2.083px 2.083px 2.084px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: none;
            background: var(--0, #FFF);

            &:hover {
                background: var(--gray-3, #F5F5F5);
            }
        }
    }

    &--request-list-accordion{
        display:flex;
        justify-content:space-between;
        align-items:center;
        cursor:pointer;
        margin-bottom: 16px;

        .request-title{
            font-size:14px;
            font-weight:500;
            line-height:20px;
            color:#3C3E49;

            .request-count{
                margin-left:4px;
                font-weight:400;
                color:#83889E;
                display:none;

                &.active{
                    display:inline;
                }
            }
        }

        svg{
            transition: .2s ease-in;

            &.reverse{
                transform: rotate(-180deg);
                transition: .2s ease-in;
            }
        }
    }

    >main {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    hr {
        border: 0;
        height: 1px;
        width: 100%;
        margin-block-end: 0;
        color: var(--base-blue-grey-3, #DFE0E6);
        background: var(--base-blue-grey-3, #DFE0E6);
    }

    .request-list-separator{
        margin: 0 0 16px;
    }

    &__form, &__permissions.request {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        &.general-share{
            justify-content: space-between;

            div{
                display: flex;
                align-items: center;
                flex-grow: inherit;

                .toggle-general-access{
                    padding:16px;
                    margin-right: -16px;
                    cursor: pointer;
                    transform: rotate(-180deg);

                    &.reverse{
                        transform: rotate(0);
                        transition: .2s ease-in;
                    }
                }
            }
        }

        h3 {
            flex-grow: 1;
            color: var(--base-blue-grey-10, #3C3E49);

            /* Body/SM/Medium */
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;

            /* 142.857% */
            letter-spacing: 0.14px;

            &.requester-email{
                max-width: 155px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        >div {
            position: relative;
            display: flex;
            flex-grow: 1;

            .autoCompleteSelectInput {
                input {
                    padding-right: 150px;
                }
            }

            .selectBox {
                position: absolute;
                right: 8px;
                top: 6px;
            }
        }

        .autoCompleteSelectInput {
            input {
                height: 36px;
            }
        }

        button {
            flex-grow: 0;
            border: none;
            cursor: pointer;

            /* Body/SM/Medium */
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;

            /* 142.857% */
            line-height: 20px;
            letter-spacing: 0.14px;

            &:disabled {
                opacity: 0.5;
                pointer-events: none;
                cursor: not-allowed;
                user-select: none;
            }

            &:not(:disabled):hover {
                @include hover-effect(rgba(98, 0, 234, 0.05), 0.03);
            }

            &.sendInviteBtn, &.acceptRequestBtn {
                display: flex;
                height: 36px;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                border-radius: 8px;
                background: var(--interactive-bg-p-3, #7c4dff);
                color: var(--interactive-label-p-3, #fff);
                transition: 200ms linear;
            }

            &.denyRequestBtn{
                font-size:16px;
                color:#6200EA;
                border-radius:3px;
                padding:5px 10px;
                background:transparent;

                &:hover{
                    box-shadow:none;
                    background: var(--interactive-bg-p-2, #F3EBFF);
                }
            }

            &.copyLinkBtn {
                display: flex;
                height: 36px;
                padding: 16px 24px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
                border: 1px solid rgb(179 136 255 / 30%);
                background: var(--interactive-bg-sec, #FFF);
                color: var(--interactive-label-s-1-t-1, #3C3E49);
                transition: 200ms linear;

            }
        }
    }

    &__permissions {
        display: flex;
        max-height: 200px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        padding-right: 14px;
        overflow-y: auto;
        
        &--user{
            .upgrade-request{
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.01em;
                color: #3C3E49;
                background: #FFE57F;
                border-radius: 3px;
                padding: 4px 8px;
                cursor: pointer;
                white-space: nowrap;
            }
        }

        &.share{
            max-height:0;
            overflow:hidden!important;
            margin-bottom:-16px;
            justify-content:normal;
            transition: max-height 0.15s ease-out;
            padding:0;

            &.active{
                max-height:150px;
                height:auto;
                margin:0!important;
                transition: max-height 0.15s ease-in;
            }

            >li{
                padding:2px 0;
            }
        }

        &.requestWrapper{
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.25s ease-out;
            padding: 0;

            &.active{
                max-height: 108px;
                transition: max-height 0.25s ease-in;
                margin: 0!important;
            }
        }

        &.request{
            justify-content:normal;
            padding:0;
            margin-bottom:16px;

            >li{
                padding:2px 0;
            }
        }

        &--request{
            justify-content: space-between;
            max-width:100%!important;
            width:100%;

            &-message{
                padding:8px 4px;
                border-radius:5px;

                &:hover{
                    background: var(--interactive-bg-p-2, #F3EBFF);
                }
            }
        }

        @include scrollbar;

        &--item {
            display: flex;
            padding: 4px 0;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;

            main {
                display: flex;
                align-items: center;
                gap: 12px;
                max-width: calc(100% - 9.5rem);


                aside {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 8px 6.5px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    flex-shrink: 0;
                    border-radius: 50%;
                    color: var(--base-purple-0, #FBF8FF);

                    /* Body/SM/Medium */
                    font-family: var(--font-family-default);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;

                    /* 142.857% */
                    letter-spacing: 0.14px;

                    &.letters {
                        border: 2px solid var(--0, #FFF);
                        background: var(--base-purple-3, #7C4DFF);
                    }
                }

                h3 {
                    color: var(--base-blue-grey-10, #3C3E49);

                    /* Body/Base/Regular */
                    font-family: var(--font-family-default);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;

                    /* 150% */
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    i {
                        margin-left: 5px;
                    }

                    &:hover>.customTooltip {
                        opacity: 1;
                        z-index: 1010;
                    }
                }
            }


            &.loading {
                display: flex;
                padding: 20px 0;
                justify-content: center;
                align-items: center;
                gap: 12px;
                align-self: stretch;

                main {
                    >h3 {
                        color: var(--blue-grey-8, var(--base-blue-grey-8, #83889E));

                        /* 142.857% */
                        line-height: 20px;
                    }
                }
            }

            &.temp {
                main {
                    >aside {
                        border: 1px dashed var(--base-blue-grey-8, #83889E);
                    }

                    >h3 {
                        color: var(--base-blue-grey-8, #83889E);
                    }
                }
            }

            &.non-registered-user {
                main {
                    > aside {
                        border: 1px dashed var(--base-blue-grey-8, #83889e);
                        background-color: unset;
                    }

                    h3 {
                        color: var(--base-blue-grey-8, #83889e);
                    }
                }
            }
        }

        &--request-left, &--request-right{
            display:flex;
            align-items:center;
            gap:12px;
        }

        &--role {
            display: flex;
            padding: 4px 12px;
            align-items: center;
            gap: 8px;
            border-radius: 3px;
            text-align: center;

            /* Body/SM/Regular */
            font-family: var(--font-family-default);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            /* 142.857% */
            letter-spacing: 0.14px;

            &.designer {
                background-color: rgb(255 97 1 / 10%);
                color: rgba(#FF6101, 0.5);
            }

            &.developer {
                background-color: rgb(6 78 59 / 10%);
                color: rgba(#064E3B, 0.5);
            }

            &.owner {
                background: var(--interactive-bg-p-2, #F3EBFF);
                color: var(--base-blue-grey-10, #3C3E49);
            }

            &.productologist {
                background-color: rgb(255 0 0 / 10%);
                color: rgba(#F00, 0.5);
            }
        }
    }

    &__tooltip {
        svg {
            left: 16px !important
        }
    }
}

.request-message-tooltip{
    max-width: 338px;
    box-sizing:border-box;
    margin-bottom:-15px!important;
    padding-bottom:15px;
    word-break: break-all;
}

details > summary {
  list-style: none!important;
}

details > summary::-webkit-details-marker {
  display: none!important;
}