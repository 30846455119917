@mixin menu-list-mixin {
    width: 26px;
    position: absolute;
    bottom: 40px;
    left: 5px;
    padding: 4px;
    background: #FFF;
    box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-flow: column;

    &::before {
        content: "";
        position: absolute;
        right: calc(50% - 3px);
        bottom: -4px;
        width: 0;
        height: 0;
        border-top: solid 4px #fff;
        border-left: solid 3px transparent;
        border-right: solid 3px transparent;
    }

    div {
        padding: 2px 4px;
        border-radius: 3px;
        margin: 2px 0;

        em {
            color: #292D32;
            font-size: 18px;
        }

        &:hover {
            background: #B388FF;

            em, span, svg, small {
                color: #fff!important;
            }

            svg path {
                stroke: #fff!important;
            }
        }

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }

    &.onBottom {
        bottom: unset;
        top: 50px;

        &::before {
            border-top: none;
            border-bottom: 4px solid #fff;
            bottom: unset;
            top: -4px;
        }
    }
}

.toolControls {
    background: #FFF;
    box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
    border-radius: 5px;
    padding: 4px 7px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 10;
    user-select: none;

    .button { 
        position: relative;
        padding: 3px 4px;        
        margin: 0 3px;
        border-radius: 3px;
        color: #292D32;
        background: transparent;
        border: none;
        outline: none;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
        
        &.imageDownloading{
            svg{
                opacity: 0.2;
            }
            
            &:hover .tooltipText{
                opacity:1;
                visibility: visible;
            }
        }

        &:not(.disabled, .imageDownloading):hover {          
          background: #B388FF;
          color: #fff;

          .tooltipText {
            opacity: 1;
            visibility: visible;
          }

          .button__svg-icon {
            fill: #fff;
          }

          svg path {
            stroke: #fff;
          }

          &.addHyperlinkBtn {
            path {
                stroke: #fff;
            }
          }
        }

        &.svg_stroke {
            &:hover {
                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }

        em {
          font-size: 24px;
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.icon-repeat { font-size: 18px; }
          &.icon-text { font-size: 16px; }

        //   &.line-arrow {
        //     position: relative;
        //     &::after {
        //         content: '>';
        //         font-size: 17px;
        //         right: -2px;
        //         position: absolute;
        //     }
        //   }
        }

        .icon {
            height: 24px;
            width: 24px;
            &.delete {background: url("../../assets/images/trash.svg") no-repeat 0 0/cover;}
            &.lock {background: url("../../assets/images/lock.svg") no-repeat 0 0/cover;}
            &.copy {background: url("../../assets/images/document-copy.svg") no-repeat 0 0/cover;}
            &.expandMore {background: url("../../assets/images/more-square.svg") no-repeat 0 0/cover;}
            &.sendFront {background: url("../../assets/images/send-front.svg") no-repeat 0 0/cover;}
            &.sendBack {background: url("../../assets/images/send-back.svg") no-repeat 0 0/cover;}
            
        }
    }

    .textSizeBtn {
        height: 30px;
        display: flex;
        align-items: center;
        position: relative;
        margin: 0 3px;
        padding: 0 3px;

        &:not(.disabled):hover {
            .tooltipText {
                opacity: 1;
                visibility: visible;
            } 
        }
    }

    .highlightTextDDL {   
        &__button {
            padding: 3px 4px;
            cursor: pointer;
            border-radius: 3px;
            background: #fff;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0;
            background-color: none;
            border: none;

            svg {
                transition: 200ms all;
            }

            &.active, &:not(.disabled):hover {
                background-color: #B388FF;
                color: #fff;

                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }

        &:not(.disabled):hover {
            .tooltipText {
                opacity: 1;
                visibility: visible;
            }
        }

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }
    }

    .resizeDDL, .colorOptionDDL, .fontSizeDDL, .fontFamilyDDL, .contentJustifyDDL, .fontStyleDDL, .changeShapeDDL {
        padding: 3px 4px;
        margin: 0 3px;

        // background: #B388FF;
        border-radius: 3px;
        color: #292D32;
        cursor: pointer;
        position: relative;
        width: 35px; 
        height: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;       

        &:not(.changeShapeDDL).disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }

        em {
            position: relative;
            top: 1px;
            font-size: 14px;
        }

        .listItems, .colorList, .alignmentListItems, .fontStyleListItems, .changeShapeMenuListItems {
            @include menu-list-mixin;
        }

        .colorPicker {
            height: 20px;
            width: 20px;
            padding: 0;
            border: 0;
            margin: 0;
            border-radius: 3px;

            &::before {
                content: '';
                width: 42px;
                height: 27px;
                position: absolute;
                left: 0;
                top: 0;
                background: transparent;
                z-index: 1;
                cursor: pointer;
            }
        }

        &:not(.disabled):hover, &.active {          
            background: #B388FF;
            color: #fff;

            &.changeShapeDDL {
                > svg path {
                    stroke: #fff;
                }
            }
        }

        &:not(.disabled):hover {
            .tooltipText {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .filtersDDL {
        position: relative;

        .filters-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;

            .left {
                margin-right:7px;

                small {
                    display: block;
                    color: var(--blue-grey-7, #9B9FB1);
                    font-size: 10px;
                    line-height: 12px;
                }

                span {
                    display: block;
                    color: var(--blue-grey-10, #3C3E49);
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }

        .filters-menu {
            @include menu-list-mixin;

            min-width: 127px;
            width: max-content;
            padding: 0;
            left: 50%;
            transform: translateX(-50%);

            .menu-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                padding: 14px 17px;
                width: 100%;
                box-sizing: border-box;

                .item-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    padding:0;
                    margin: 0;
                    border-radius: 3px;

                    em {
                        color: var(--blue-grey-10, #3C3E49);
                    }

                    span {
                        margin-left: 8px;
                        margin-right: 8px;
                        font-size: 12px;
                        color: var(--blue-grey-10, #3C3E49);
                    }
                }

                small {
                    color: var(--blue-grey-7, #9B9FB1);
                    font-size: 12px;
                }
            }
        }

        &.filter-applied {
            order: -1;

            + .seperator {
                order: -1;
            }
        }

        &.disabled {
            .filters-button {
                opacity: 0.5;
                cursor: not-allowed;
            }
        }
    }

    .disabled {
        &.changeShapeDDL {
            svg, em {
                opacity: 0.2!important;
                cursor: not-allowed!important;
            }
        }
    }

    .colorOptionDDL {
        .innerColorSelector {
            border: 1px solid red;
        }

        .colorTransparentWrapper {
            background-image: url('../../assets/images/transparent-bg.jpeg');
            background-position: center;
            border-radius: 3px;
        }

        .styled-field {
            width: 18px;
            height: 18px;
            border-radius: 3px;
            border-style: solid;
            border-width: thin;
            border-color: rgb(0 0 0);
        }

        &__wrapper {
            position: relative;
        }

        &:not(.disabled):hover {
            .tooltipText {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .fontFamilyDDL {
        width: 80px;        

        .icon{
            &.fontFamily {
                width: auto;
                justify-content: flex-start;
                padding-left: 4px;

                em {
                    max-width: 65px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .listItems {
            bottom: auto;
            top: 40px;
            width: max-content;

            &::before {
                content: "";
                position: absolute;
                inset: -4px auto auto 10px;
                width: 0;
                height: 0;
                border-top: transparent 4px;
                border-left: solid 3px transparent;
                border-right: solid 3px transparent;
                border-bottom: solid 4px #fff;
            }

            .icon { 
                width: auto;     
                padding: 0 5px;           
                justify-content: flex-start;
            }
        }
    }

    .contentJustifyDDL {
        .alignmentListItems {
            .alignLeft, .alignCenter, .alignRight {
                &.active {
                    background: #B388FF;

                    em {
                        color: #fff;
                    }
                }
            }
        }
    }

    .fontStyleDDL {
        .fontStyleListItems {
            .underLineBlock, .italicBlock, .boldBlock {
                &.active {
                    background: #B388FF;

                    em {
                        color: #fff;
                    }
                }
            }
        }
    }

    .changeShapeDDL {
        .changeShapeMenuListItems {
            padding: 8px;
            width: unset;

            .menu-item {
                width: 36px;
                height: 36px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &:not(:first-child) {
                    margin-top: 18px;
                }

                &:hover {
                    em {
                        color: #fff;
                    }

                    svg path, svg circle {
                        stroke: #fff;
                    }
                }
            }
        }

        .tooltipText {
            white-space: nowrap;
            width: auto;
        }

        &:hover {
            .tooltipText {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    .seperator {
        margin: 0 10px;
        background-color: var(--blue-grey-3, #dfe0e6);
        width: 1px;
        height: 24px;
    }

    // secondary tools option
    .indexingControls {
        position: absolute;
        right: -140px;
        top: 0;       
        padding: 8px 10px 8px 7px;
        background: #FFF;
        box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);
        box-sizing: border-box;
        border-radius: 5px;

        .separator { 
            height: 1px;
            background-color: #b2b2b2;
            width: 100%;
            margin: 7px auto;
        }

        .button {
            // margin: 4px;
            font-size: 14px;
            .icon { margin: 0 0 0 16px;}
        }
    }

    .deleteBlock {
        margin: 0 0 0 3px;
        position: relative;
        cursor: pointer;

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }

        .button {
            margin-right: 0;
        }

        em {
            font-size: 10px;
            margin: 0 3px 0 10px;
            padding: 0;
            width: unset;
        }

        .deleteOptionsBlock {
            position: absolute;
            left: 0;
            top: 33px;
            visibility: hidden;
            opacity: 0;
            transition: all .2s ease-in-out;
            border: 1px solid #ECEDF0;

            .deleteOptionsInner {
                width: 179px;
                height: auto;
                background: #fff;
                border-radius: 4px;
                padding: 4px;
                box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);

                &__item {
                    height: 40px;
                    padding: 0 12px;
                    display: flex;
                    align-items: center;
                    font-family: var(--font-family-default);
                    color: var(--color-default-text);
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.01em;

                    &.active {
                        border-radius: 4px;
                        background: #EFEFEF;
                    }

                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        &:not(.disabled).active {
            .deleteOptionsBlock {
                left: 0;
                visibility: visible;
                opacity: 1;
                transition: all .2s ease-in-out;
            }

            .button {
                background-color: #B388FF;
                color: white;
            }

            path {
                stroke: white;
            }

            em {
                transform: rotate(180deg);
            }
        }
    }

    .moreOption {
        padding: 3px 4px;
        margin: 0 3px;
        position: relative;
        cursor: pointer;

        &.disabled {
            opacity: 0.2;
            cursor: not-allowed;
        }

        em {
            font-size: 21px;
            color: #292D32;
            position: relative;
            top: 2px;
        }

        .moreOptionsBlock {
            position: absolute;
            left: 25px;
            top: -4px;
            visibility: hidden;
            opacity: 0;
            transition: all .2s ease-in-out;

            .moreOptionsInner {
                width: 140px;
                margin-left: 13px;
                height: auto;
                background: #fff;
                border-radius: 4px;
                padding: 4px;
                box-shadow: 0 6px 18px rgb(0 0 0 / 5.53%);

                .front, .back {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 36px;
                    padding: 0 6px;
                    border-bottom: solid 1px rgb(191 191 191 / 30%);

                    span {
                        font-family: var(--font-family-default);
                        color: var(--color-default-text);
                        font-weight: 400;
                        font-size: 16px;
                    }

                    em {
                        color: var(--color-default-text);
                        font-size: 24px;
                    }

                    &:hover {
                        border-radius: 4px;
                        background: #EFEFEF;
                    }

                    &:last-child {
                        border: none;
                    }
                }
            }
        }

        &:not(.disabled):hover {
            .moreOptionsBlock {
                left: 30px;
                visibility: visible;
                opacity: 1;
                transition: all .2s ease-in-out;
            }
        }
    }

    .lineArrowOption {
        &__wrapper {
            position: relative;
        }

        &__list {
            position: absolute;
            padding: 4px;
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
            width: auto;
            display: grid;
            grid-template-columns: 32px 32px 32px 32px;
            column-gap: 6px;
            bottom: unset;
            top: 40px;
            left: 50%;
            transform: translateX(-50%);

            &::before {
                content: "";
                position: absolute;
                right: calc(50% - 3px);
                width: 0;
                height: 0;
                top: -8px;
                border-top: solid 4px transparent;
                border-left: solid 3px transparent;
                border-right: solid 3px transparent;
                border-bottom: solid 4px #fff;
            }

            em {
                cursor: pointer;
                position: relative;
                top: 1px;
                font-size: 14px;
            }
        }

        &__btn {
            em {
                font-size: 20px;
            }
        }

        &__item {
            margin: 0;
            padding: 4px;
            aspect-ratio: 1;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:not(:disabled):hover, &.active {
                background: #B388FF;

                em {
                    color: #fff;
                }
            }

            em {
                font-size: 20px;
            }
        }
    }

    .lineAddText {
        svg {
            width: 24px;
            height: 24px;
        }
    }

    .tooltipText{
        font-size: 8px;
        line-height: 12px;
        background-color: black;
        color: #fff;
        text-align: center; 
        border-radius: 4px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);

        // bottom: calc(-40px / 2 + -6px);
        top: calc(-40px / 2 + -6px);
        visibility: visible;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
        pointer-events: none;

        &.maxContent {
            width: max-content;
        }

        &::after {
            content: "";
            position: absolute;

            // top: -3px;
            left: 50%;
            bottom: -9px;
            transform: translateX(-50%);
            margin-top: -5px;
            border-width: 5px;
            border-style: solid;

            // border-color: transparent transparent black transparent;
            border-color: black transparent transparent;
        }

        &.onBottom {
            bottom: calc(-40px / 2 + -6px);
            top: unset;

            &::after {
                top: -3px;
                bottom: unset;
                border-color: transparent transparent black;
            }
        }
        
    }

    .arrow_wrapper {
        margin-left: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .hyperLinkWrapper {
        position: relative;

        .hyperlinkInputWrapper{
            position: absolute;
            top: 44px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            width: 349px;
            transform: translateX(-50%);
            background-color: #fff;
            border:1px solid #DFE0E6;
            box-shadow: 0 6px 18px 0 rgb(0 0 0 / 6%);
            border-radius: 5px;
            max-height: 32px;
            box-sizing: border-box;
            padding-right: 8px;

            input {
                width: 100%;
                flex: 1 1 100%;
                max-width: 100%;
                height: 32px;
                background-color: unset;
                outline: none;
                border: 0;
                font-size: 12px;
                padding: 8px;
                box-sizing: border-box;

                &::placeholder {
                    color: #AFB2C0;
                    font-weight: 400;
                    opacity: 1;
                }
            }

            button {
                background-color: transparent;
                border: none;
                color: #6200EA;
                text-decoration: underline;
                font-size: 12px;
                cursor: pointer;
                padding: 0;
            }
        }
    }

    .layer-container {
        position: relative;

        .layer-list {
            position: absolute;
            margin-top: 15px;
            right: 0;

            .layer-list-wrapper {
                box-shadow: 0 6px 18px 0 #0000000e;

                &::before {
                    content: '';
                    position: absolute;
                    right: calc(50% - 37px);
                    top: -8px;
                    border-bottom: solid 8px #fff;
                    border-left: solid 7px transparent;
                    border-right: solid 7px transparent;
                }

                &.onBottom{
                    &::before {
                        top: unset;
                        bottom: -8px;
                        border-top: solid 8px #fff;
                        border-bottom: unset;
                    }   
                }
            }

            &.onBottom {
                bottom: 46px;
            }
        }
    }
}

.wb_toast__downloadImage-loading {
    border: 1px solid var(--Brand-Purple-4, #651fff)!important;
    padding: 8px 11px 8px 16px !important;
}

.downloadImage_toast{
    &__text{
        font-size:14px;
        line-height:20px;
        font-family:Rubik, sans-serif;
        display:flex;
        align-items:center;
        
        &--main span{
            font-weight:500;
        }
    }

    &__loader{
        width:17px;
        height:17px;
        margin-right:8px;

        svg{
            vertical-align:top;
        }
    }
}

.Toastify{
    &__toast-icon {
        margin-right:8px!important;
    }
}

#downloadImage-loading{
    .Toastify__close-button{
        svg{
            width:18.5px;
            height:18.5px;
        }
    }
}

.shape-layer-change-toast{    
    .content{
        display: flex;
        align-items: center;
    }

    .Toastify__close-button{
        margin-bottom: 2px;
    }
}
