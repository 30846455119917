.more-menu-button{
    position:absolute;
    right:12px;
    top:12px;
    z-index:999;
    border:1px solid #B388FF4D;
    border-radius:8px;
    height:36px;
    width:36px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:#fff;

    &.active, &:hover{
        // border-width:2px;
        background:#F3EBFF;
        transition:.1s all;
    }
    
    .more-menu-list{
        display:none;
        position:absolute;
        top:-2px;
        right:-183px;
        background-color:#fff;
        border-radius:8px;
        border:1px solid #ECEDF0;
        width:180px;
        overflow:hidden;

        &.active{
            display:block;
        }

        &.right-to-left{
            left:-183px;
            right:unset;
        }

        &-item{
            display:flex;
            align-items:center;
            gap:8px;
            padding:12px 16px;

            &:hover{
                background-color:#F3EBFF;
            }

            &:last-child img{
                height:14px;
                width:14px;
            }
        }

        &-item-icon{
            height:15px;
            width:15px;
        }

        &-item-label{
            font-size:12px;
            line-height:16px;
            font-family:Rubik, sans-serif;
            color:#3C3E49;
        }
    }
}

.restore-wrapper{
    display:flex;
    align-items:center;
    position:absolute;
    right:12px;
    top:12px;
    gap:8px;
    z-index:999;

    span{
        font-size:14px;
        font-weight:500;
        line-height:20px;
        color:#6200EA;
    }
}