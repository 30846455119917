
.color-picker {
    position: relative;
    padding: 16px 12px;
    width: 256px;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    margin-top: 10px;

    .colors-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: -4px;

        .color-item {
            width: 16px;
            height: 16px;
            padding: 4px;
            box-sizing: content-box;
            position: relative;

            .color-box {
                border-radius: 2px;
                width: 100%;
                height: 100%;
                cursor: pointer;
                user-select: none;

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #fff;
                    border-radius: 2px;
                }
     
                &::after {
                    content: "";
                    position: absolute;
                    left: 3px;
                    top: 3px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--color-base-grey-2);
                    border-radius: 2px;
                    display: none;
                }
            }

            &.switcher {
                .color-box {
                    background: conic-gradient(from 90deg at 50% 50%, #00FF75 -32.55deg, #00E0FF 32.03deg, #0047FF 89.91deg, #FF00E5 150.83deg, #F00 215.17deg, #FFE600 270.73deg, #00FF75 327.45deg, #00E0FF 392.03deg);
                }
            }

            &.active {
                .color-box {
                    &::before {
                        border-color: var(--color-apeiros-purple);
                    }
                }
            }

            &.bordered:not(.active) {
                .color-box {
                    &::after {
                        display: block;
                    }
                }
            }
        }
    }

    .all-colors-wrapper, .recent-colors-wrapper {
        .title {
            margin-bottom: 8px;
            display: block;
            color: var(--color-blue-grey-10);
            font-size: 12px;
        }
    }

    .all-colors-wrapper {
        border-top: 1px solid var(--color-base-grey-2);
        margin-top: 12px;

        .title {
            margin-top: 12px;
        }
    }

    .saturation-wrapper {
        width: 100%;
        height: 110px;
        overflow: hidden;
        position: relative;
        border-radius: 8px;
        padding-bottom: 75px;
        box-sizing: border-box;
        margin-top: 12px;
    }

    .hue-wrapper {
        position: relative;
        margin-top: 8px;
        width: 100%;
        height: 10px;
        box-sizing: border-box;

        .hue-horizontal {
            border-radius: 8px;
        }
    }

    .alpha-wrapper {
        position: relative;
        margin-top: 8px;
        margin-bottom: 8px;
        width: 100%;
        height: 10px;
        box-sizing: border-box;

        > div {
            > div {
                border-radius: 8px;
            }
        }
    }

    .hex-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;

        input {
            width: 144px;
            height: 36px;
            padding: 8px;
            border-radius: 4px 0 0 4px;
            border: 1px solid var(--color-blue-grey-5);
            box-sizing: border-box;
            outline: none;
            color: var(--color-blue-grey-10);
        }

        .hex-input-wrapper {
            flex: auto;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-flow: row nowrap;

            label {
                margin-right: 16px;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }

            input {
                border-right-width: 0;
                border-radius: 4px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                font-size: 14px;
                letter-spacing: 0.01em;
            }
        }

        .alpha-input-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: nowrap;
            position: relative;

            input {
                width: 51px;
                padding: 0 8px;
                font-size: 14px;
                border-radius: 4px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                /* Chrome, Safari, Edge, Opera */
                &::-webkit-outer-spin-button,
                &::-webkit-inner-spin-button {
                    appearance: none;
                    margin: 0;
                }

                /* Firefox */
                &[type="number"] {
                    appearance: textfield;
                }
            }

            span {
                position: absolute;
                right: 8px;
                top: 0%;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 38px;
                color: var(--color-blue-grey-10);
            }
        }
    }

    .custom-pointer {
        background-color: #672EC4;
        width: 16px;
        height: 16px;
        box-sizing: border-box;
        border: 2px solid #FFF;
        box-shadow: 0 6px 10px 0 rgb(0 0 0 / 8%);
        border-radius: 50%;
        transform: translate(-50%, -4px);
    }
}