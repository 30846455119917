@import '../../utils';

.layer-list-container {
    box-sizing: border-box;
    max-height: 166px;
    width: 96px;
    padding: 8px;
    border-radius: 5px;
    background-color: white;
    overflow: hidden;

    .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
        height: calc(100% - 4px) !important;
        display: block !important;
        width: 4px !important;
        top: 2px !important;
    }

    .ScrollbarsCustom-Scroller{
        margin-top: 0;
    }

    .layer-list-main {
        width: 80px;
        overflow: hidden auto;

        .layer {
            height: 22px;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 4px;
            border-radius: 3px;

            .name {
                font-size: 12px;
                color: #3c3e49;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 14.22px;
            }

            &.selected {
                background: #f3ebff;

                .name::before {
                    content: '';
                    display: inline-block;
                    width: 6px;
                    height: 6px;
                    background-color: #6200ea;
                    border-radius: 50%;
                    margin-right: 4px;
                    margin-bottom: 1px;
                }
            }

            &:hover {
                background: #f3ebff;
            }
        }
    }
}
