$borderColor: #BFBFBF;
$bgColor: #F5F5F5;
$dividerColor: #DFE0E6;

.rightBottomBlock {
    position: absolute;
    right: 12px;
    bottom: 12px;
    z-index: 9;
    user-select: none;

    .fitToScreen, .layoutBtn, .miniMapButton {
        position: relative;

        .tooltiptext {
            font-size: 10px;
            line-height: 12px;
            width: max-content;
            background-color: black;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            padding: 5px 10px;
            position: absolute;
            z-index: 1;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            top: -100%;
            pointer-events: none;
            left: 50%;
            transform: translateX(-50%);

            &::after {
              content: "";
              position: absolute;
              bottom: -10px;
              left: calc(50% - 5px);
              margin-top: 10px;
              border-width: 5px;
              border-style: solid;
              border-color: black transparent transparent;
            }

            &.tooltip__page {
                top: -210%;
            }
        }

        &:not(.disabled):hover {
            .tooltiptext {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .wrapper {
        display: grid;
        grid-template-columns: auto auto auto ;
        column-gap: 20px;
        position: relative;

        .fitToScreen,
        .miniMapBottomIcon,
        .helpButton {
            em {
                font-size: 15px;
                color: var(--color-default-text);
                position: relative;
                top: 2px;
                pointer-events: none;
            }
        }

        .fitToScreen {
            transition: background 0.1s ease-in; 

            &:hover {
                background: var(--Base-Purple-1, #F3EBFF);
            }
        }

        .pageSelector {
            padding: 10px;
            cursor: initial;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            width: 127px;
            max-width: 157px;

            .numbersArea {
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: nowrap;
                width: 70px;
                max-width: 90px;
                color: var(--color-default-text);

                .numbers {
                        margin-left: 10px;
                        margin-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: nowrap;
                
                        span {
                            color: #3C3E49;
                            text-align: center;
                            font-family: var(--font-family-default);
                            font-size: 12px;
                        }
                
                        i {
                            font-style: inherit;
                            font-size: 12px;
                        }
                    }
            }

            button {
                background-color: transparent;
                outline: none;
                border: none;
                cursor: pointer;
                width: auto;
                height: auto;
                padding: 0;

                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }

                &.layoutBtn {
                    position: relative;
                    display: flex;
                    align-items: center;
                }

                &.layoutBtn.__active {
                    svg path {
                        stroke: var(--color-apeiros-purple-dark);
                    }
                }
            }

            .divider {
                width: 1px;
                height: 24px;
                background-color: $dividerColor;
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .feedbackButton,
    .tutorialButton {
        &__wrapper {
            height: 58px;
            position: absolute;
            bottom: 100%;
            visibility: hidden;
            opacity: 0;
            transition: 100ms all ease-in-out;
        }

        padding: 10px;
        bottom: calc(100%);
        margin-bottom: 10px;

        img {
            width: 100%;
        }
    }

    .tutorialButton__wrapper {
        bottom: calc(200% + 10px);
    }
}

.leftBottomBlock {
    position: absolute;
    left: 12px;
    bottom: 12px;
    z-index: 99;
    transition: 200ms all ease-in-out;
    will-change: transform;

    &.leftDrawerActive {
        transform: translateX(409px);
    }

    .wrapper {
        display: grid;
        grid-template-columns: auto auto auto ;
        column-gap: 20px;
        position: relative; 

        .zoomIn-zoomOut {
            width: 103px;
            position: relative;
            display: grid;
            grid-template-columns: auto auto auto;
            cursor: initial;
            padding: 0 10px !important;

            .zoomOut,
            .zoomIn {
                background: transparent;
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: -1px;
                cursor: pointer;

                em {
                    font-size: 10px;
                    color: var(--color-default-text);
                    height: 10px;
                }
            }

            .zoomIn {
                margin-left: 0;
                margin-right: -1px;
            }

            .zoomOut {
                em {
                    position: relative;
                }
            }

            .percentValue {
                color: var(--color-default-text);
                font-size: 12px;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 50px;
                height: 100%;
                user-select: none;

                em {
                    position: relative;
                    top: 3px;
                    cursor: pointer;
                }
            }

            .zoomOptions {
                background: #fff;
                user-select: none;
                position: absolute;
                bottom: 120%;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 8px;
                border: 1px solid var(--base-blue-grey-2, #ECEDF0);
                box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
                width: 65px;
                opacity: 0;
                visibility: hidden;
                transition: 200ms all ease-in-out;
                display: grid;
                flex-direction: column;

                &.active {
                    visibility: visible;
                    opacity: 1;
                }

                &__item {
                    padding-top: 5px;
                    padding-bottom: 5px;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    width: 100%;
                    transition: 200ms all ease-in-out;
                    box-sizing: border-box;
                    cursor: pointer;
                    color: var(--color-default-text);
                    font-size: 12px;

                    &:hover {
                        background: #E7E7E7;
                    }

                    &.disabled {
                        opacity: .25;
                        pointer-events: none;
                    }          
                }
            }
        }

        .tabOpenerBottom {
            z-index: 1;
            transition: all .2s ease-in-out;

            em {
                font-size: 16px;
                position: relative;
                color: var(--color-default-text);
            }

            .tooltiptext {
                font-size: 10px;
                line-height: 12px;
                width: max-content;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 4px;
                padding: 5px 10px;
                position: absolute;
                z-index: 1;
                left: 50%;
                top: calc(-50% - 15px);
                transform: translateX(-50%);
                visibility: hidden;
                opacity: 0;
                transition: all 0.3s ease-in-out;
                pointer-events: none;

                &::after {
                    content: "";
                    position: absolute;
                    top: calc(100% + 5px);
                    left: calc(50% - 5px);
                    margin-top: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: black transparent transparent;
                }
            }

            &.disabled {
                cursor: not-allowed !important;
                user-select: none;

                em {
                    color: rgb(107 106 106) !important;
                }
            }

            &:not(.disabled):hover, &:not(.disabled).active {
                background: var(--Base-Purple-1, #F3EBFF);

            }

            &:not(.disabled):hover {
                .tooltiptext {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

.bottomBarButton {
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border: 1px solid rgb(179 136 255 / 30%);
    background:  #FFF;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}
